<template>
  <v-layout row wrap>
    <span class="subheading" style="text-decoration: underline">{{ current_data_time }}</span>
    <div id="pick-date" style="position: relative"></div>
    <v-menu
      v-model="pick_data_menu"
      :close-on-content-click="false"
      :nudge-width="240"
      offset-y
      :right="lang === 'en'" 
      :left="lang !== 'en'"
      absolute
      attach="#pick-date"
    >
      <template v-slot:activator="{ on, attrs }">
        <img
          slot="activator"
          src="@/assets/images/cabinet_details_icons/calendar.svg"
          alt="calendar"
          style="height: 20px; width: 20px; cursor: pointer"
          :class="lang === 'en' ? 'ml-2' : 'mr-2'"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-card class="styled-rounded-card" style="background-color: white !important">
        <v-card-title class="px-3 pt-3 pb-0">
          <span class="subheading font-weight-bold">{{ $t('Pick date & time') }}</span>
          <v-spacer></v-spacer>
          <v-icon style="font-size: 20px" color="black" @click="closePickerMenu">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-2 px-3 pb-3">
          <v-layout d-flex align-end>
            <v-flex xs5>
              <span class="property-name subheading">{{ $t('Date') }}</span>
            </v-flex>
            <v-flex xs7 :class="lang === 'en' && 'ml-3' || 'mr-3'">
              <span class="property-name subheading">{{ $t('Time') }}</span>
            </v-flex>
          </v-layout>
          <v-layout d-flex align-end class="date-style">
            <v-flex xs5>
              <v-menu
                ref="menu"
                v-model="show_custom"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="custom_date"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="custom_date"
                    readonly
                    v-on="on"
                    style="margin-bottom: 1px;"
                    hide-details
                    class="mt-0 pt-0 property-name body-1"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="custom_date" no-title scrollable :max="max_date_to_pick">
                  <v-spacer></v-spacer>
                  <v-btn flat color="#292F7D" @click="show_custom = false">{{ $t('Cancel') }}</v-btn>
                  <v-btn flat color="#292F7D" @click="$refs.menu.save(custom_date)">{{ $t('Ok') }}</v-btn>
                </v-date-picker>
              </v-menu> 
            </v-flex>
            <v-flex xs7 :class="lang === 'en' && 'ml-3' || 'mr-3'">
              <input
                v-model="custom_time"
                @change="setTime"
                type="time"
                class="property-name"
                style="border-bottom: 1px solid #6a6a6a;"
                required
              />
            </v-flex>
          </v-layout>
          <v-layout class="mt-3">
            <v-btn
              class="text-transform-none"
              color="#292F7D"
              outline
              round
              small
              :class="lang === 'en' ? 'mr-2' : 'ml-2'"
              @click="closePickerMenu"
            >
              <span>{{ $t('Cancel') }}</span>
            </v-btn>
            <v-btn
              class="text-transform-none white--text"
              color="#292F7D"
              round
              small
              @click="updateCurrentData"
            >
              <span>{{ $t('Ok') }}</span>
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-layout>
</template>

<script>
import moment from 'moment-timezone';

export default {
    name: 'DateMenu',
    props: ['current_data_time', 'max_date', 'index'],
    components: {},
    data() {
      return {
        pick_data_menu: false,
        show_custom: false,
        custom_date: null,
        custom_time: null,
        max_date_to_pick: null
      };
    },
    mounted() {
         const now = moment().utcOffset(0).valueOf();
        
        if (this.max_date) {
            this.max_date_to_pick = this.max_date;
        }else {
            this.max_date_to_pick = moment(now).format('YYYY-MM-DD');
        }
        this.setDefaultCustomData();
    },
  computed: {
    projectTimezone(){
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    },
    lang() {
      return this.$store.state.Global.lang;
    }
  },
  methods: {
    setTime(e) {
      this.custom_time = e.target.value;
    },
    closePickerMenu(){
      this.pick_data_menu = false;
      this.setDefaultCustomData();
    },
    setDefaultCustomData() {
        const now = moment().utcOffset(0).valueOf();
        
        this.custom_date = moment(now).format('YYYY-MM-DD');
        this.custom_time = moment(now).format('hh:mm');
    },
    updateCurrentData() {
      if (this.index) {
        this.$emit('updateCurrentData', this.custom_date, this.custom_time, this.index);
      }else {
        this.$emit('updateCurrentData', this.custom_date, this.custom_time);
      }
      this.closePickerMenu();
    }
  }
}
</script>