<template>
  <div class="styled-scroller">
    <v-layout row wrap align-center justify-space-between class="mt-4 min-height-btn">
      <span class="subheading font-weight-bold">{{ $t('Harmonics Magnitude - Voltage') }}</span>
      <v-flex d-flex justify-center style="direction: ltr">
        <v-btn
          v-for="tab in harmonicTabs"
          :key="tab"
          class="font-weight-bold pa-0 my-0 mx-2"
          style="min-width: fit-content !important"
          :color="selectedTab === tab ? '#292F7D' : 'grey'"
          flat
          large
          @click="setSelectedTab(tab)"
        >
          <span>{{ tab }}</span>
        </v-btn>
      </v-flex>
      <v-btn @click="generateExcel" color="#292F7D" outline round style="border: 2px solid">
        <img src="@/assets/images/tondo_icons/export_icon.svg" alt="calendar" style="height: 20px; width: 20px"/>
        <span :class="lang === 'en' ? 'ml-2' : 'mr-2'" class="text-transform-none subheading font-weight-bold">{{ $t('Export') }}</span>
      </v-btn>
    </v-layout>
    <v-layout justify-space-between>
      <v-flex xs12 md6 class="styled-rounded-card pa-3" :class="lang ==='en' ? 'mr-2' : 'ml-2'">
        <HarmonicsBar
          v-if="barData"
          :barData="barData"
          :harmonics="harmonics"
          :display_harmonics="display_harmonics"
          :selectedTab="selectedTab"
        />
      </v-flex>
      <v-flex xs12 md6 class="styled-rounded-card pa-3">
        <v-layout row class="mb-3">
          <v-flex xs2>
            <span class="subheading">X axis</span>
          </v-flex>
          <v-flex xs3 :key="`${series_data.name}`" v-for="([series_num, series_data]) in Object.entries(harmonics_series)" :class="series_num === '2' ? 'mx-3' : ''">
            <v-layout align-center>
              <div class="square_alert" :class="`column${series_num}_color ${lang === 'en' ? 'mr-2' : 'ml-2'}`"></div>
              <span class="font-weight-bold subheading">{{ $t(`${series_data.name}`) }}</span>
            </v-layout>
            <DateMenu :current_data_time="harmonics_string_data_time[series_num]" @updateCurrentData="updateCurrentData" :index="series_num"/>
          </v-flex>
        </v-layout>
        <v-layout column style="max-height: 300px; overflow: auto" class="mb-2">
          <v-layout row :key="harmonic" v-for="harmonic in harmonics" class="mb-2">
            <v-flex xs2>
              <v-layout wrap>
                <v-flex d-flex shrink>
                  <v-checkbox
                    v-model="display_harmonics[harmonic]"
                    hide-details
                    class="ma-0 pa-0"
                    @change="(e) => handleDisplayChanged(harmonic)"
                  ></v-checkbox>
                </v-flex>
                <span class="font-weight-bold">{{ harmonic }}</span>
              </v-layout>
            </v-flex>
            <v-flex xs3 :key="slot" v-for="slot in [1,2,3]" :class="slot === 2 ? 'mx-3' : ''">
              <span>{{ harmonics_data[slot][1][`${selectedTab}_${harmonic}`] }}</span>
            </v-flex>
          </v-layout>
        </v-layout>
        <span v-if="display_count_error" class="red--text">{{ errorMessage }}</span>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import HarmonicsBar from './HarmonicsBar.vue';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import DateMenu from './DateMenu.vue';
import { i18n } from '@/main';
import Utils from '@/modules/Utils';

export default {
  name: 'HarmonicsTab',
  props: ['streamData', 'getDataByAbsoluteTime', 'cabinet_id', 'getParsedData'],
  components: { HarmonicsBar, DateMenu },
  data() {
    return {
      harmonicTabs: ['V1', 'I1', 'V2', 'I2', 'V3', 'I3'],
      selectedTab: 'V1',
      harmonics_data: {
        1: [],
        2: [],
        3: [],
      },
      harmonics_string_data_time: {
        1: '',
        2: '',
        3: ''
      },
      harmonics_series: {
        1: { name: i18n.t('Column 1'), color: '#60c6e1'},
        2: { name: i18n.t('Column 2'), color: '#b469df'},
        3: { name: i18n.t('Column 3'), color: '#7869e2'}
      },
      harmonics: [],
      min_harmonic: 2,
      max_harmonic: 20,
      max_harmonic_display: 9,
      display_count_error: false,
      display_harmonics: {},
      lastData: null,
      barData: null,
    };
  },
  mounted() {
    const stream_data = this.streamData.length > 3 ? this.streamData.slice(-3) : cloneDeep(this.streamData);
    if (stream_data && stream_data.length) {
      Array.from({ length: this.max_harmonic - this.min_harmonic + 1}, (v, i) => i + this.min_harmonic)
        .map((harmonic_num) => `H${harmonic_num.toString().padStart(2, 0)}`)
        .forEach((harmonic_string, index) => {
          this.harmonics.push(harmonic_string);
          this.$set(this.display_harmonics, harmonic_string, index + 1 <= this.max_harmonic_display);
        });
      
      stream_data.forEach((data, index) => {
        const result = this.getSeriesData(data);
        this.harmonics_data[index + 1] = result[0];
        this.harmonics_string_data_time[index + 1] = result[1];
      });

    }
   
    this.updateBarData();
  },
  computed: {
    errorMessage() {
      return this.$t('You can choose up to {{max_choose}} harmonics', {
        max_choose: this.max_harmonic_display
      });
    },
    projectTimezone(){
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    },
    lang() {
      return this.$store.state.Global.lang;
    },
    absoluteStreamHistory() {
      return this.$store.state.ProjectStreams.absoluteStreamHistory;
    }
  },
  methods: {
    handleDisplayChanged(harmonic){
      let selected_count = Object.values(this.display_harmonics).filter((is_displayed) => is_displayed).length;

      if (selected_count > this.max_harmonic_display){
        this.display_count_error = true;
        this.$nextTick(() => {
          this.$set(this.display_harmonics, harmonic, false);
        });
      }else {
        this.display_count_error = false;
      }
    },
    getSeriesData(data) {
      const parsed_data = this.getParsedData(data[1]);
      const harmonics_fields = {};
      Object.entries(parsed_data).filter(([field, value]) => field.includes('_H')).forEach(([field, value]) => harmonics_fields[field] = value);
      
      return [[data[0], harmonics_fields], moment(data[0]).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm')];
    },
    async updateCurrentData(custom_date, custom_time, series_num){
      const [data, timestamp_string, timestamp] = await this.getDataByAbsoluteTime(custom_date, custom_time);
      if (Object.keys(data).length) {
        this.harmonics_data[series_num][1] = data;
      }else {
        const no_data = {};
        this.harmonicTabs.forEach((tab) => no_data[`${tab}_${this.harmonics[0]}`] = this.$t('No Data'));
        this.harmonics_data[series_num][1] = no_data;
      }
      this.harmonics_data[series_num][0] = timestamp;
      this.harmonics_string_data_time[series_num] = timestamp_string;

      this.updateBarData(series_num);
    },
    generateExcel(){
      const workbook = new Excel.Workbook();
      // const columns = this.harmonics.map((harmonic) => {
      //   const key = `${this.selectedTab}_${harmonic}`;
      //   return ({ header: key, key, width: 20 });
      // });

      const columns = [
        {header: this.$t('Field'), key: 'field', width: 15}
      ];

      const rows = this.harmonics.map((harmonic) => ({field: `${this.selectedTab}_${harmonic}`}));

      if (this.harmonics_data[1].length) {
        columns.push({header: this.harmonics_string_data_time[1], key: 'value1', width: 20});
        rows.forEach((row) => row.value1 = this.harmonics_data[1][1][row.field]);
      }

      if (this.harmonics_data[2].length) {
        columns.push({header: this.harmonics_string_data_time[2], key: 'value2', width: 20});
        rows.forEach((row) => row.value2 = this.harmonics_data[2][1][row.field]);
      }

      if (this.harmonics_data[3].length) {
        columns.push({header: this.harmonics_string_data_time[3], key: 'value3', width: 20});
        rows.forEach((row) => row.value3 = this.harmonics_data[3][1][row.field]);
      }

      const harmonics_sheet = workbook.addWorksheet(this.$t('Harmonics Data'));
      harmonics_sheet.columns = columns;
      harmonics_sheet.addRows(rows);

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;'});
        saveAs(blob, `Cabinet ${this.cabinet_id} - Harmonics Data - ${Utils.getNow(this.projectTimezone)}.xlsx`);
      });
    },
    setSelectedTab(tabName) {
      this.selectedTab = tabName;
      this.updateBarData();
    },
    updateBarData(series_num) {
      let barData = [];

      if (series_num) {
        barData = cloneDeep(this.barData);
        const series_data = this.harmonics_data[series_num][1];
        if (Object.keys(series_data).length > this.harmonicTabs.length) {
          barData[series_num - 1].data = this.harmonics.map((harmonic) => series_data[`${this.selectedTab}_${harmonic}`]);
        }else {
          barData[series_num - 1].data = this.harmonics.map((harmonic) => 0);
        }
      }else {
        barData = [
          { ...this.harmonics_series[1], data: [] },
          { ...this.harmonics_series[2], data: [] },
          { ...this.harmonics_series[3], data: [] }
        ];
        Array.from({length: 3}, (v, i) => i).forEach((series) => {
          const series_data = this.harmonics_data[series + 1][1];
          if (Object.keys(series_data).length > this.harmonicTabs.length) {
            barData[series].data = this.harmonics.map((harmonic) => series_data[`${this.selectedTab}_${harmonic}`]);
          }else {
            barData[series].data = this.harmonics.map((harmonic) => 0);
          }
        });
      }

      this.barData = barData;
    }
  }
};
</script>

<style scoped>
.column1_color {
  background-color: #60c6e1;
}

.column2_color {
  background-color: #b469df;
}

.column3_color {
  background-color: #7869e2;
}
</style>