var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-layout',{staticClass:"font-style"},[(_vm.drawer)?_c('div',{staticClass:"overlay"}):_vm._e(),_c('v-navigation-drawer',{staticClass:"styled-scroller history-dialog-style",staticStyle:{"margin-top":"95px","border":"solid 1px #dbe0e8 !important"},style:(_vm.lang === 'en' 
            ? 'border-top-left-radius: 16px; border-bottom-left-radius: 16px;' 
            : 'border-top-right-radius: 16px; border-right-left-radius: 16px;'),attrs:{"absolute":"","right":_vm.lang === 'en'},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-card',[_c('v-card-title',{staticClass:"pt-4 pb-0",class:_vm.lang === 'en' ? 'pl-4' : 'pr-4'},[_c('v-layout',[_c('h3',[_vm._v(_vm._s(_vm.$t('Operation State History')))]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-0",attrs:{"icon":"","flat":"","small":""},on:{"click":_vm.setExitDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.operation_history.length)?_c('v-layout',{staticClass:"mt-3",staticStyle:{"word-break":"break-word"},attrs:{"xs12":"","row":"","wrap":""}},_vm._l((Object.entries(_vm.headers)),function(ref){
            var field_name = ref[0];
            var value = ref[1];
return _c('v-flex',{key:("header-" + field_name),class:("" + (value.flex))},[_c('span',{staticClass:"property-name mb-3"},[_vm._v(_vm._s(_vm.$t(value.text)))])])}),1):_vm._e()],1),_c('v-card-text',{staticClass:"px-4 pt-4 pb-2",class:_vm.operation_history.length ? 'dialog-content-with-data' : 'dialog-content-without-data'},[_vm._l((_vm.operation_history),function(prop_value,index){return _c('v-layout',{key:("operation-state-" + index),staticClass:"mb-2"},_vm._l((Object.entries(prop_value)),function(ref){
            var field = ref[0];
            var value = ref[1];
return _c('v-flex',{key:("operation-state-" + index + "-" + field),class:("" + (_vm.headers[field].flex))},[_c('span',{staticClass:"property-name mb-3",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(field === 'operation_state' ? _vm.$t(value) : value))])])}),1)}),(_vm.items < _vm.days_back && _vm.display_load_btn)?_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"text-transform-none",attrs:{"color":"#292F7D","outline":"","round":"","small":"","loading":_vm.loading_data},on:{"click":_vm.updateTotalItems}},[_c('span',[_vm._v(_vm._s(_vm.$t('load more')))]),_c('v-icon',[_vm._v("keyboard_arrow_down")])],1)],1):(!_vm.display_load_btn && !_vm.operation_history.length)?_c('v-layout',{attrs:{"justify-center":"","align-center":"","fill-height":""}},[_c('span',{staticClass:"property-name mb-3",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.$t('No history')))])]):_vm._e()],2)],1),_c('v-card',{staticClass:"px-3"},[_c('v-card-actions',{staticClass:"min-height-btn"},[_c('v-btn',{staticClass:"text-transform-none white--text",class:_vm.lang === 'en' ? 'mr-2' : 'ml-2',attrs:{"color":"#292F7D","round":""},on:{"click":_vm.setExitDialog}},[_c('span',[_vm._v(_vm._s(_vm.$t('Exit')))])])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }