





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, User } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';

@Component({
    components: {}
})
export default class OperationHistoryDialog extends Vue{
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @Global.State('allProjectsTimezone') allProjectsTimezone;
    @User.State('role') role;

    drawer = true;
    loading = true;
    utils = Utils;
    ticket_data = null;
    operation_history = [];
    loading_data = false;
    display_load_btn = true;
    days_back = 365;
    items = 10;
    limit = 10;
    headers = {
        date: {
            text: 'Date',
            flex: 'xs5'
        },
        updater: {
            text: 'Username',
            flex: 'xs4'
        },
        operation_state: {
            text: 'Operation State',
            flex: 'xs3'
        }
    };

    @Prop() device_id;
    
    async mounted() {
        await this.fetchOperationHistory();
        this.loading = false;
    }

    @Watch('items')
    async fetchOperationHistory() {
        if (this.items > this.days_back) return;
        this.loading_data = true;
        const property_history = await PropUtils.fetchPropertyHistory(`devices/${this.device_id}`, 'cabinet.operation_state', `${this.days_back}days`, this.items);
        if (this.operation_history.length === property_history.length) {
            this.display_load_btn = false;
        }else {
            this.generateHistoryData(property_history);
        }
        this.loading_data = false;
    }

    updateTotalItems(){
        this.items += this.limit;
    }

    generateHistoryData(property_history) {
        if (property_history) {
            this.operation_history = property_history
                .map((row) => {
                    let value = null;
                    try {
                        value = typeof row.value === 'string' ? JSON.parse(row.value) : row.value;
                        if (!value) {
                            value = {date: '', updater: '', operation_state: ''};
                        }
                    }catch(e) {
                        value = {date: '', updater: '', operation_state: ''};
                    }

                    const state = value.cabinet_opstate ? PropUtils.getFieldLowerToUpper(value.cabinet_opstate) : '';
                    let updater = value.updater ? value.updater.split('@') : '';
                    updater = updater && updater.length ? updater[0] : '';

                    return {
                        date: value.date ? value.date * 1000 : '',
                        updater,
                        operation_state: state === 'Delivered' ? 'Operational' : state
                    }})
                .sort((row1, row2) => row2.date - row1.date)
                .map((row) => ({
                    ...row,
                    date: row.date && Utils.convertTimestamp(row.date, 'DD/MM/YYYY HH:mm', this.timezone) || row.date
                }));
        }
    }

    get timezone(){
        return this.projectTimezone || this.allProjectsTimezone || 'Asia/Tel_Aviv';
    }

    setExitDialog(){
        this.$emit('setOperationHistoryDialog');
    }
}

