<template>
  <div id="chart" style="width: 100%">
    <VueApexCharts
      type="bar"
      height="400"
      width="100%"
      :options="chartOptions"
      :series="filteredBarData"
    ></VueApexCharts>
  </div>
</template>
<script>

import VueApexCharts from 'vue-apexcharts';
import { cloneDeep } from 'lodash';
export default {
  name: 'HarmonicBar',
  components: { VueApexCharts },
  props: ['barData', 'harmonics', 'display_harmonics', 'selectedTab'],
  data() {
    return {
      dates: [],
      chartOptions: {},
      filteredBarData: null
    };
  },
  created() {
    this.updateChartData();
  },
  watch: {
    selectedTab() {
      this.updateChartData();
    },
    display_harmonics: {
      handler() {
        this.updateChartData();
      },
      deep: true
    },
    barData() {
      this.updateChartData();
    }
  },
  methods: {
    updateChartData() {
      const harmonics_data = cloneDeep(this.barData);
      harmonics_data[0].data = this.getFilteredHarmonics(harmonics_data[0].data);
      harmonics_data[1].data = this.getFilteredHarmonics(harmonics_data[1].data);
      harmonics_data[2].data = this.getFilteredHarmonics(harmonics_data[2].data);

      this.filteredBarData = harmonics_data;
      this.chartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '40%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: this.harmonics.filter((harmonic) => this.display_harmonics[harmonic])
        },
        yaxis: {
          labels: {
            offsetX: -15
          }
        }
      };
    },
    getFilteredHarmonics(data){
      const filtered_data = [];
      data.forEach((harmonic_value, index) => {
        if (this.display_harmonics[this.harmonics[index]]) {
          filtered_data.push(harmonic_value);
        }
      });
      return filtered_data;
    }
  }
};
</script>