
















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Reports, Global, User, Projects, ProjectStreams } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import Utils from '@/modules/Utils';
import { cloneDeep } from 'lodash';
import API from '@/modules/API';
import LoadingDialog from '@/components/LoadingDialog.vue';
import { event_abnormal, event_consumption_anomalies, single_unit_probability_alert_types, alerts_with_header_2, alerts_with_header_3 } from '@/store/modules/Global';
import moment from 'moment-timezone';
import chartConfig from '@/pages/mapView/components/MapTabs/devices/chart.config';
import VueApexCharts from 'vue-apexcharts';

@Component({
    components: {
        ConfirmDialog,
        LoadingDialog,
        VueApexCharts
    }
})
export default class IncidentDetailsDialog extends Vue{
    @Global.State('lang') lang;
    @Global.State('dusk_dawn_data') dusk_dawn_data;
    @Global.State('timezone') projectTimezone;
    @Global.State('allProjectsTimezone') allProjectsTimezone;
    @Global.Action('throwNotify') throwNotify;
    @Reports.Getter('commissionedFixtures_map') commissionedFixtures_map;
    @Reports.Getter('commissionedCabinets') commissionedCabinets;
    @Reports.Getter('commissionedCabinets_map') commissionedCabinets_map;
    @User.State('role') role;
    @User.State('isManager') isManager;
    @Projects.Mutation('deleteIncident') deleteIncident;
    @ProjectStreams.State('absoluteStreamHistory') absoluteStreamHistory;
    @ProjectStreams.Action('geAbsoluteStreamHistory') geAbsoluteStreamHistory;

    @Prop() dialog_data;
    @Prop() expected_consumption;

    drawer = true;
    loading = true;
    utils = Utils;
    ticket_data = null;
    device = null;
    cabinet = null;
    incident_details = [];
    close_incident_dialog = false;
    loading_dialog = false;
    series = [];
    chartOptions = {};
    show_graph = false;
    
    async mounted() {
        this.chartOptions = cloneDeep(chartConfig.chartOptionsEnergy);
        this.chartOptions['yaxis'].opposite = false;
        if (this.lang === 'he') {
            this.chartOptions['yaxis'].labels = {offsetX: -20, formatter: (val) => val.toFixed(2)};
        }else {
            this.chartOptions['yaxis'].labels = {formatter: (val) => val.toFixed(2)};
        }
        this.setAdditionalIncidentData();
        this.incident_details = this.getFinalIncidentDetails();
        if (this.ticket_data.event_type) {
            if (this.ticket_data.event_type.toLowerCase().includes('voltage') || this.ticket_data.event_type.toLowerCase().includes('consumption')){
                this.show_graph = true;
                await this.fetchTotalEnergy();
                this.setChartData();
            }
        }

        this.loading = false;
    }

    get timezone(){
        return this.projectTimezone || this.allProjectsTimezone || 'Asia/Tel_Aviv';
    }

    setAdditionalIncidentData(){
        this.ticket_data = cloneDeep(this.dialog_data);
        if (this.ticket_data.alert_type){
            this.ticket_data.parsed_description = this.$t(Utils.getTicketDescription(this.ticket_data.title));
            this.ticket_data.parsed_priority = this.$t(Utils.getAlertPriority(this.ticket_data.priority_id));
        }else {
            this.ticket_data.parsed_description = this.$t(Utils.getTicketDescription(this.ticket_data.event_type));
        }
        this.ticket_data.parsed_time = Utils.convertTimestamp(this.ticket_data.created_at, 'DD/MM/YYYY HH:mm:ss', this.timezone);
        const incident_device = this.commissionedFixtures_map.get(this.ticket_data.device);

        if (incident_device) {
            this.device = incident_device;
            if (this.device) {
                this.ticket_data.device_name = this.device && this.device.name || this.$t('N/A');
                this.ticket_data.device_address = Utils.getDeviceAddress(this.device['meta.device'].address, this.device['meta.device'].estimated_address);
                this.ticket_data.circuit_number = this.device['meta.device'].circuit_number || this.$t('N/A');
                this.ticket_data.pole_number = this.device['meta.device'].pole_number || this.$t('N/A');
                this.ticket_data.phase = this.device['meta.device'].phase || this.$t('N/A');
                this.ticket_data.pole_height = this.device['meta.device'].phole_height || this.$t('N/A');
                this.ticket_data.watts = this.device['meta.device'].watts || this.$t('N/A');
                this.ticket_data.fixture_manufacturer = this.device['meta.device'].fixture_manufacturer || this.$t('N/A');
                this.ticket_data.socket_type = this.ticket_data.socket_type || this.$t('N/A');
            }
        }

        const incident_cabinet = incident_device 
            ? this.commissionedCabinets.find((cabinet) => cabinet['meta.device'].cabinet_id === this.ticket_data.cabinet)
            : this.commissionedCabinets_map.get(this.ticket_data.device);

        if (incident_cabinet) {
            this.cabinet = incident_cabinet;
            if (this.cabinet) {
                if (!this.device) {
                    this.ticket_data.device_name = this.cabinet && this.cabinet.name || this.$t('N/A');
                }
                this.ticket_data.managed_area = this.cabinet['meta.device'].managed_area || this.cabinet['meta.device'].road || this.$t('N/A');
                this.ticket_data.cabinet_id = this.ticket_data.cabinet;
                this.ticket_data.cabinet_address = Utils.getDeviceAddress(this.cabinet['meta.device'].cabinet_address, this.cabinet['meta.device'].estimated_address);
                this.ticket_data.iec_supply_pole_id = this.cabinet['meta.device'][`iec's_supply_pole_id`] || this.$t('N/A');
            }
        }
    }

    async fetchTotalEnergy() {
        const ticket_time = moment(this.ticket_data.created_at).valueOf();
        const before_12_hours = moment(ticket_time).subtract(12, 'hours').valueOf();
        const after_12_hours = moment(ticket_time).add(12, 'hours').valueOf();

        const parameters = {
            stream_name: 'total_energy',
            aggregators: [],
            tags: {
               device_id: [this.ticket_data.device]
            },
            start: before_12_hours,
            end: after_12_hours
        };
        await this.geAbsoluteStreamHistory(parameters);
    }

    setChartData(){
        const parsed_data = this.absoluteStreamHistory[0].values.map((stream_data) => {
            try {
                const stream_value = JSON.parse(stream_data[1]);
                return [stream_data[0], stream_value];
            }catch (e) {
                return null;
            }
        }).filter((stream_data) => stream_data !== null);
        if (!parsed_data.length) {
            this.show_graph = false;
            return;
        }
        let y_annotation_value = 0, y_annotation_text = '';

        if (this.ticket_data.event_type.toLowerCase().includes('voltage')){
            y_annotation_text = `${this.$t('Unusual Measure')} ${this.ticket_data.unusual_measure}`;
            const name = this.ticket_data.phase === 'R' ? 'V1' : this.ticket_data.phase === 'S' ? 'V2' : 'V3';
            const data = parsed_data.map((stream_data) => ({x: stream_data[0], y: stream_data[1][name]}));
            this.series.push({name, data});
            this.chartOptions['title'].text = name;
            y_annotation_value = +this.ticket_data.unusual_measure.slice(0, -1);
            if (this.ticket_data.event_type.toLowerCase().includes('under')) {
                this.chartOptions['yaxis'].min = y_annotation_value - 1;
            }else {
                this.chartOptions['yaxis'].max = y_annotation_value + 1;
            }
        }else {
            y_annotation_value = +this.expected_consumption.toFixed(2);
            y_annotation_text = `${this.$t('Expected Consumption')} ${y_annotation_value}`;
            this.chartOptions['yaxis'].max = y_annotation_value + 1;
            const series_data = [];
            let yaxis_max = this.chartOptions['yaxis'].max;
            
            parsed_data.forEach((stream_data) => {
                const y = stream_data[1].I1 + stream_data[1].I2 + stream_data[1].I3;
                if (y > yaxis_max){
                    yaxis_max = y + 2;
                }
                series_data.push({x: stream_data[0], y});
            });

            if (yaxis_max !== this.chartOptions['yaxis'].max) {
                this.chartOptions['yaxis'].max = yaxis_max;
            }

            this.series = [{name: this.$t('Current'), data: series_data}];
            this.chartOptions['title'].text = this.$t('Current');
            this.chartOptions['yaxis'].title.text = 'Amps';
        }

        this.chartOptions['annotations'] = {
            yaxis: [{
                y: y_annotation_value,
                borderColor: 'red',
                label: {
                    position: this.lang === 'he' ? 'left' : 'right',
                    style: {
                        color: '#fff',
                        background: '#FF0000'
                    },
                    text: y_annotation_text
                },
                strokeDashArray: 0, // Solid line
                offsetX: 0 // Ensure the line spans the entire x-axis
            }]
        };

        this.chartOptions['title'].style = {
            fontSize: '14px',
            fontFamily: 'Open Sans, Arial, monospace'
        };
        this.chartOptions['yaxis'].title.style = {
            fontSize: '14px',
            fontFamily: 'Open Sans, Arial, monospace'
        };
        if (this.lang === 'he') {
            this.chartOptions['yaxis'].title.offsetX = -25;
        }
    }

    get allowed_to_close(){
        return this.role === 'CA' || this.isManager;
    }

    async closeIncident(){
        this.close_incident_dialog = false;
        this.loading_dialog = true;
        const body = {
            ticket_id: `${this.ticket_data.id}`
        };

        const url = 'incidents/close';
        try {
            const response = await API.dashboardAPI(url, 'POST', {}, body);
            const incidents_type = this.ticket_data.alert_type ? 'alerts' : 'events';

            this.deleteIncident({id: this.ticket_data.id, project_id: this.ticket_data.project_id, cabinet_id: this.ticket_data.cabinet_id, incidents_type });
            this.loading_dialog = false;
            this.throwNotify({
              type: 'success',
              title: this.$t('Success'),
              text: this.$t(`The incident was closed successfully`)
            });
            this.$emit('handleIncidentClosed');
        }catch (e) {
            this.loading_dialog = false;

            this.throwNotify({
              type: 'error',
              title: this.$t('Error'),
              text: this.$t(`Couldn't close the incident`)
            });
        }
        this.setExitDialog();
    }

    getFinalIncidentDetails(){
        let start = [
            ['Device Id', this.ticket_data.device],
            ['Ticket Id', this.ticket_data.id],
            ['Priority', this.ticket_data.parsed_priority],
            ['Description', this.ticket_data.parsed_description],
            ['Date Time', this.ticket_data.parsed_time],
            ['Device Name', this.ticket_data.device_name]
        ];

        if (this.device) {
            start.push(['Device Address', this.ticket_data.device_address]);
        }

        if (this.ticket_data.event_type) {
            const additional_columns = [];

            if (this.ticket_data.consumption_category.toLowerCase() === event_abnormal) {
                additional_columns.push(['Phase', this.ticket_data.phase]);
                additional_columns.push(['Unusual Measure', this.ticket_data.unusual_measure]);
            }else if (this.ticket_data.consumption_category.toLowerCase() === event_consumption_anomalies) {
                additional_columns.push(['Expected Consumption', this.ticket_data.expected_consumption]);
                additional_columns.push(['Actual Consumption', this.ticket_data.actual_consumption]);
                additional_columns.push(['Percentage Deviation', this.ticket_data.percentage_deviation]);
            }else {
                additional_columns.push(['Actual Consumption', this.ticket_data.actual_consumption]);
            }
            start = start.slice(0, 2).concat(start.slice(3).concat(additional_columns));
        }else if (single_unit_probability_alert_types.includes(this.ticket_data.alert_type)) {
            start.push(['Probability', `${this.ticket_data.probability}%`]);
        }else if (alerts_with_header_2.includes(this.ticket_data.alert_type)) {
            start.push(['Phase', Utils.convertNumberToRealPhase(this.ticket_data.phase)]);
        }else if (alerts_with_header_3.includes(this.ticket_data.alert_type)) {
            start.push(['Circuit Number', this.ticket_data.circuit_number]);
        }

        let end = [
            ['Cabinet Id', this.ticket_data.cabinet_id],
            ['Managed Area', this.ticket_data.managed_area],
            [`IEC's Supply Pole ID`, this.ticket_data.iec_supply_pole_id],
            ['Cabinet Address', this.ticket_data.cabinet_address]
        ];
    
        if (this.device) {
            const device_fields = [
                ['Circuit Number', this.ticket_data.circuit_number],
                ['Pole Number', this.ticket_data.pole_number],
                ['Phase', this.ticket_data.phase],
                ['Pole Height', this.ticket_data.pole_height],
                ['Watts', this.ticket_data.watts],
                ['Fixture Manufacturer', this.ticket_data.fixture_manufacturer],
                ['Socket Type', this.ticket_data.socket_type]
            ];

            end = end.concat(device_fields);
        }
        
        return start.concat(end);
    }

    setExitDialog(){
        this.$emit('setIncidentDialog', false);
    }
}

