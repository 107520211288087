<template>
  <div>
    <div class="center mb-3" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="py-3">
      <v-layout v-if="allData && allData.length" class="tabs-monitor mb-3">
        <div @click="setSelectedTab('demands')" :class="['tab-monitor', selectedTab === 'demands' ? 'selected-tab-monitor' : '']">
          {{ $t('Demands') }}
        </div>
        <div @click="setSelectedTab('harmonics')" :class="['tab-monitor', selectedTab === 'harmonics' ? 'selected-tab-monitor' : '']">
          {{ $t('Harmonics') }}
        </div>
        <div @click="setSelectedTab('phasor')" :class="['tab-monitor', selectedTab === 'phasor' ? 'selected-tab-monitor' : '']">
          {{ $t('Phasor') }}
        </div>
      </v-layout>
      <v-layout v-else justify-center>
        <h3>{{ $t('No Data Available') }}</h3>
      </v-layout>
      <div v-if="selectedTab === 'demands'">
        <DemandsTab
          :streamData="allData"
          :getDataByAbsoluteTime="getDataByAbsoluteTime"
          :cabinet_id="cabinet_id"
          :getParsedData="getParsedData"
        />
      </div>
      <div v-else-if="selectedTab === 'harmonics'">
        <HarmonicsTab
          :streamData="allData"
          :getDataByAbsoluteTime="getDataByAbsoluteTime"
          :cabinet_id="cabinet_id"
          :getParsedData="getParsedData"
        />
      </div>
      <div v-else-if="selectedTab === 'phasor'">
        <PhasorTab :streamData="allData"
          :getDataByAbsoluteTime="getDataByAbsoluteTime"
          :cabinet_id="cabinet_id"
          :getParsedData="getParsedData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API, { Types } from '@/modules/API';
import DemandsTab from './monitorTabs/DemandsTab.vue';
import HarmonicsTab from './monitorTabs/HarmonicsTab.vue';
import PhasorTab from './monitorTabs/PhasorTab.vue';
import vuex from '@/store';
import Utils from '@/modules/Utils';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

export default {
  name: 'MonitorTab',
  components: { DemandsTab, HarmonicsTab, PhasorTab },
  data() {
    return {
      loading: null,
      allData: null,
      selectedTab: 'demands',
      device: null,
      cabinet_id: ''
    };
  },
  async mounted() {
    this.loading = true;
    this.device = this.$store.getters['Reports/commissionedCabinets_map'].get(this.$route.params.id);
    this.cabinet_id = this.device && this.device['meta.device'].cabinet_id || '';
    await this.fetchDataByRelativeTime();
    this.loading = false;
  },
  computed: {
    projectTimezone(){
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    },
    absoluteStreamHistory() {
      return this.$store.state.ProjectStreams.absoluteStreamHistory;
    }
  },
  methods: {
    async fetchDataByRelativeTime() {
      try {
        const response = await API.get(
          Types.DEVICES,
          `${
            this.$route.params.id
          }/streams/${`${this.$route.params.id}.pm_data_log`}/history/`,
          { since: '1weeks' },
          {}
        );
        for (const i of response) {
          i[1] = JSON.parse(i[1]);
        }
        this.allData = response;
      } catch (e) {
        console.log(e);
      }
    },
    async getDataByAbsoluteTime(custom_date, custom_time){
      const timestamp_time = moment(`${custom_date} ${custom_time}`, Utils.getFullDateFormat()).valueOf();
      const before_1_hour = moment(timestamp_time).subtract(1, 'hours').valueOf();
      const parameters = {
        stream_name: 'pm_data_log',
        aggregators: [],
        tags: {
          device_id: [this.device.id]
        },
        start: before_1_hour,
        end: timestamp_time
      };
      await vuex.dispatch('ProjectStreams/geAbsoluteStreamHistory', parameters);

      let data = {}, timestamp = timestamp_time, timestamp_string = '';
      if (this.absoluteStreamHistory.length && this.absoluteStreamHistory[0].values && this.absoluteStreamHistory[0].values.length) {
        const pm_data = this.absoluteStreamHistory[0].values.slice(-1)[0];
        timestamp = pm_data[0];
        timestamp_string = moment(pm_data[0]).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm');
        data = this.getParsedData(pm_data[1]);
      }
      if (!Object.keys(data).length) {
        timestamp_string = moment(`${custom_date} ${custom_time}`, Utils.getFullDateFormat()).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm');
      }

      return [data, timestamp_string, timestamp];
    },
    setSelectedTab(tabName) {
      this.selectedTab = tabName;
    },
    getParsedData(data) {
      let parsed_data = cloneDeep(data);

      if (typeof data === 'string') {
        try {
          parsed_data = JSON.parse(JSON.parse(data).replaceAll('\'', '\"'));
        }catch (e) {
          try {
            parsed_data = JSON.parse(data.replaceAll('\'', '\"'));
          }catch (e) {
            parsed_data = {};
          }
        }
      }

      return parsed_data;
    }
  }
};
</script>

<style scoped>

.tabs-monitor {
  max-width: 100%;
  height: 30px;
  width: max-content;
  background-color: #fff;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
  border-radius: 16px;
  border: 1px solid #332a8f;
}

.tab-monitor {
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  height: 100%;
  justify-content: center;
  align-content: center;
  color: #332a8f;
}

.selected-tab-monitor {
  border-radius: 12px;
  background-color: #332a8f;
  color: #fff !important;
}

</style>