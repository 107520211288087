<template>
  <div v-if="current_data">
    <v-layout class="mt-4 min-height-btn pick-date-style">
      <v-flex d-flex align-center>
        <span class="subheading font-weight-bold" :class="lang === 'en' ? 'mr-1' : 'ml-1'">{{ $t('Data collected at') }}</span>
        <DateMenu :current_data_time="current_data_time" @updateCurrentData="updateCurrentData"/>
      </v-flex>
      <v-flex d-flex justify-end>
        <v-btn @click="generateExcel" color="#292F7D" outline round style="border: 2px solid">
          <img src="@/assets/images/tondo_icons/export_icon.svg" alt="calendar" style="height: 20px; width: 20px"/>
          <span :class="lang === 'en' ? 'ml-2' : 'mr-2'" class="text-transform-none subheading font-weight-bold">{{ $t('Export') }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap  class="styled-rounded-card pa-3">
        <v-flex xs12 md6>
            <v-card>
                <v-card-title class="pa-0">
                    <h3>{{ $t('Frequency') }}: {{ current_data['Frequency'] }}</h3>
                </v-card-title>
                <v-card-text class="pa-0">
                    <GaugeChart
                        v-if="current_data"
                        :chartData="current_data"
                        :selectedTabs="selected_tabs"
                        :tabs_colors="tabs_colors"
                    />
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs6 md3>
            <v-card>
                <v-card-title class="pa-0">
                    <h3>{{ $t('Voltage') }}</h3>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-layout
                        v-for="(voltage_num) in Object.keys(display_voltage)"
                        :key="voltage_num"
                        align-center
                        class="styled-rounded-card px-1 my-2"
                        style="flex: none; width: 220px;"
                    >
                        <v-layout column class="pa-2">
                            <v-layout d-flex row wrap align-center justify-start>
                                <v-checkbox
                                    v-model="display_voltage[voltage_num]"
                                    hide-details
                                    class="ma-0 pa-0"
                                    @change="updateSelectedTabs(voltage_num)"
                                ></v-checkbox>
                                <div class="square_alert" :class="lang === 'en' ? 'mr-1' : 'ml-1'" :style="`background-color: ${tabs_colors[voltage_num]}`"></div>
                                <span class="property-name font-weight-medium">{{ voltage_num }}</span>
                            </v-layout>
                        </v-layout>
                        <v-layout column class="pa-2">
                            <h4>{{ current_data[`Phasor_${voltage_num}_MGT`] }}</h4>
                            <span class="property-name font-weight-medium">{{ $t('Magnitude') }}</span>
                        </v-layout>
                        <v-layout column class="pa-2">
                            <h4>{{ current_data[`Phasor_${voltage_num}_Angle`] }}</h4>
                            <span class="property-name font-weight-medium">{{ $t('Angle') }}</span>
                        </v-layout>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs6 md3>
            <v-card>
                <v-card-title class="pa-0">
                    <h3>{{ $t('Current') }}</h3>
                </v-card-title>
                <v-card-text class="pa-0">
                    <v-layout
                        v-for="(current_num) in Object.keys(display_current)"
                        :key="current_num"
                        align-center
                        class="styled-rounded-card px-1 my-2"
                        style="flex: none; width: 220px;"
                    >
                        <v-layout column class="pa-2">
                            <v-layout d-flex row wrap align-center justify-start>
                                <v-checkbox
                                    v-model="display_current[current_num]"
                                    hide-details
                                    class="ma-0 pa-0"
                                    @change="updateSelectedTabs(current_num)"
                                ></v-checkbox>
                                <div class="square_alert" :class="lang === 'en' ? 'mr-1' : 'ml-1'" :style="`background-color: ${tabs_colors[current_num]}`"></div>
                                <span class="property-name font-weight-medium">{{ current_num }}</span>
                            </v-layout>
                        </v-layout>
                        <v-layout column class="pa-2">
                            <h4>{{ current_data[`Phasor_${current_num}_MGT`] }}</h4>
                            <span class="property-name font-weight-medium">{{ $t('Magnitude') }}</span>
                        </v-layout>
                        <v-layout column class="pa-2">
                            <h4>{{ current_data[`Phasor_${current_num}_Angle`] }}</h4>
                            <span class="property-name font-weight-medium">{{ $t('Angle') }}</span>
                        </v-layout>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Utils from '@/modules/Utils';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import DateMenu from './DateMenu.vue';
import GaugeChart from './GaugeChart.vue';

export default {
  name: 'PhasorTab',
  props: ['streamData', 'getDataByAbsoluteTime', 'cabinet_id', 'getParsedData'],
  components: {DateMenu, GaugeChart},
  data() {
    return {
        tabs_colors: {
            'V1': '#60c6e1',
            'V2': '#5fb1a9',
            'V3': '#b469df',
            'I1': '#7ac6ff',
            'I2': '#7869e2',
            'I3': '#5598fb'
        },
        selected_tabs: ['V1', 'V2', 'V3', 'I1', 'I2', 'I3'],
        display_voltage: {'V1': true, 'V2': true, 'V3': true},
        display_current: {'I1': true, 'I2': true, 'I3': true},
        pick_data_menu: false,
        current_data: null,
        current_data_time: null,
        custom_date: null,
        custom_time: null,
        pm_fields: [
            {title: 'Unbalance', values: [{title: 'Unbalance V', field: 'V-Unbalance'}, {title: 'Unbalance C', field: 'C-Unbalance'}]},
            {title: 'KW', values: [{title: 'L1', field: 'kw_L1', excel: 'KW L1'}, {title: 'L2', field: 'kw_L2', excel: 'KW L2'}, {title: 'L3', field: 'kw_L3', excel: 'KW L3'}]},
            {title: 'V THD', values: [{title: 'V1 V12 THD', field: 'V1_V12_THD'}, {title: 'V2 V23 THD', field: 'V2_V23_THD'}, {title: 'V3 V31 THD', field: 'V3_V31_THD'}]},
            {title: 'I THD', values: [{title: 'I1', field: 'I1_THD', excel: 'I1 THD'}, {title: 'I2', field: 'I2_THD', excel: 'I2 THD'}, {title: 'I3', field: 'I3_THD', excel: 'I3 THD'}]},
            {title: '3P AVR', values: [{title: 'LNLL Voltage', field: '3P-AVR-LNLL-Voltage', excel: '3P AVR LNLL Voltage'}, {title: 'LL Voltage', field: '3P-AVR-LL-Voltage', excel: '3P AVR LL Voltage'}, {title: 'CURRENT', field: '3P-AVR-CURRENT', excel: '3P AVR CURRENT'}]},
            {title: 'V Max DMD', values: [{title: 'V1 Max', field: 'V1_Max_DMD', excel: 'V1 Max DMD'}, {title: 'V2 Max', field: 'V2_Max_DMD',  excel: 'V2 Max DMD'}, {title: 'V3 Max', field: 'V3_Max_DMD', excel: 'V3 Max DMD'}]},
            {title: 'I Max DMD', values: [{title: 'I1 Max', field: 'I1_Max_DMD', excel: 'I1 Max DMD'}, {title: 'I2 Max', field: 'I2_Max_DMD', excel: 'I2 Max DMD'}, {title: 'I3 Max', field: 'I3_Max_DMD', excel: 'I3 Max DMD'}]}
        ]
    };
  },
  mounted() {
    const data = this.streamData[this.streamData.length - 1];
    if (data && data.length) {
        const parsed_data = this.getParsedData(data[1]);
        this.current_data = parsed_data;
        this.current_data_time = moment(data[0]).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm');
    }
  },
  computed: {
    projectTimezone(){
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    },
    lang() {
      return this.$store.state.Global.lang;
    },
    absoluteStreamHistory() {
      return this.$store.state.ProjectStreams.absoluteStreamHistory;
    }
  },
  methods: {
    updateSelectedTabs(tab_changed) {
        if (this.selected_tabs.includes(tab_changed)) {
            this.selected_tabs = this.selected_tabs.filter((tab) => tab !== tab_changed);
        }else {
            this.selected_tabs.push(tab_changed);
        }
    },
    async updateCurrentData(custom_date, custom_time) {
      const [data, timestamp_string] = await this.getDataByAbsoluteTime(custom_date, custom_time);
      this.current_data = data;
      this.current_data_time = timestamp_string;
    },
    openEdit() {
      this.openEditTimes = !this.openEditTimes;
    },
    generateExcel(){
        const workbook = new Excel.Workbook();
        const columns = [
            {header: this.$t('Field'), key: 'field', width: 20},
            {header: this.$t('Magnitude'), key: 'magnitude', width: 15},
            {header: this.$t('Angle'), key: 'angle', width: 15}
        ];
        const data = [];
        Object.keys(this.tabs_colors).forEach((tab) => {
          data.push({field: tab, magnitude: this.current_data[`Phasor_${tab}_MGT`], angle: this.current_data[`Phasor_${tab}_Angle`]});
        });

        const sheet = workbook.addWorksheet(this.$t('Phasor'));
        sheet.columns = columns;
        sheet.addRows(data);

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;'});
            saveAs(blob, `Cabinet ${this.cabinet_id} - Phasor Data - ${Utils.getNow(this.projectTimezone)}.xlsx`);
        });
    }
  }
};
</script>

<style lang="scss">
.date-style {
  .v-text-field input {
    padding: 8px 0 1px !important;
  }

  .theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: #6a6a6a !important;
  }
}
</style>

<style scoped lang="scss">
.property-name {
  color: #6a6a6a;
}

.pick-date-style {
  .v-menu__content.theme--light.menuable__content__active {
    border-radius: 16px;
  }
}
</style>