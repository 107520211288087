

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class InputBox extends Vue{
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    @Prop() is_connections_tab;
    @Prop() input;
    @Prop() input_status;

    get input_connected() {
        return this.input && this.input.gpio_on_state_changed && this.input.gpio_on_state_changed.length && this.input.gpio_on_state_changed[0].enabled
            ? this.input.gpio_on_state_changed[0].normal_gpio_value === +this.input_status
            : this.input_status;
    }

}

