<template>
  <div v-if="current_data">
    <v-layout class="mt-4 min-height-btn pick-date-style">
      <v-flex d-flex align-center>
        <span class="subheading font-weight-bold" :class="lang === 'en' ? 'mr-1' : 'ml-1'">{{ $t('Data collected at') }}</span>
        <DateMenu :current_data_time="current_data_time" @updateCurrentData="updateCurrentData"/>
        <v-btn
          class="text-transform-none font-weight-bold"
          color="#292F7D"
          outline
          round
          style="border: 2px solid"
          :class="lang === 'en' ? 'mr-2' : 'ml-2'"
          @click="retrieveLatestData"
        >
          <span>{{ $t('Retrieve last data') }}</span>
        </v-btn>
      </v-flex>
      <v-flex d-flex justify-end>
        <v-btn @click="generateExcel" color="#292F7D" outline round style="border: 2px solid">
          <img src="@/assets/images/tondo_icons/export_icon.svg" alt="calendar" style="height: 20px; width: 20px"/>
          <span :class="lang === 'en' ? 'ml-2' : 'mr-2'" class="text-transform-none subheading font-weight-bold">{{ $t('Export') }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-layout
        v-for="(field, index) in pm_fields"
        :key="field.title"
        align-center
        class="styled-rounded-card px-2 my-2"
        style="flex: none; width: 270px; direction: ltr !important"
        :class="index === (pm_fields.length - 1) ? '' : lang === 'en' ? 'mr-3' : 'ml-3'" 
      >
        <v-layout column class="pa-3">
          <v-layout row justify-space-between>
            <span class="subheading font-weight-bold property-name">{{ field.title }}</span>
          </v-layout>
          <v-layout row>
            <v-flex v-for="field_data in field.values" :key="`${field.title}-${field_data.field}`" class="mt-2">
              <h3 class="body-2 font-weight-bold mb-1">{{ current_data[field_data.field] }}</h3>
              <span class="body-2 property-name">{{ field_data.title }}</span>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { saveAs } from 'file-saver';
import Excel from 'exceljs';
import DateMenu from './DateMenu.vue';

export default {
  name: 'DemandsTab',
  props: ['streamData', 'getDataByAbsoluteTime', 'cabinet_id', 'getParsedData'],
  components: {DateMenu},
  data() {
    return {
      last_data: null,
      pick_data_menu: false,
      last_data_time: null,
      current_data: null,
      current_data_time: null,
      custom_date: null,
      custom_time: null,
      pm_fields: [
        {title: 'Unbalance', values: [{title: 'Unbalance V', field: 'V-Unbalance'}, {title: 'Unbalance C', field: 'C-Unbalance'}]},
        {title: 'KW', values: [{title: 'L1', field: 'kw_L1', excel: 'KW L1'}, {title: 'L2', field: 'kw_L2', excel: 'KW L2'}, {title: 'L3', field: 'kw_L3', excel: 'KW L3'}]},
        {title: 'V THD', values: [{title: 'V1 V12 THD', field: 'V1_V12_THD'}, {title: 'V2 V23 THD', field: 'V2_V23_THD'}, {title: 'V3 V31 THD', field: 'V3_V31_THD'}]},
        {title: 'I THD', values: [{title: 'I1', field: 'I1_THD', excel: 'I1 THD'}, {title: 'I2', field: 'I2_THD', excel: 'I2 THD'}, {title: 'I3', field: 'I3_THD', excel: 'I3 THD'}]},
        {title: '3P AVR', values: [{title: 'LNLL Voltage', field: '3P-AVR-LNLL-Voltage', excel: '3P AVR LNLL Voltage'}, {title: 'LL Voltage', field: '3P-AVR-LL-Voltage', excel: '3P AVR LL Voltage'}, {title: 'CURRENT', field: '3P-AVR-CURRENT', excel: '3P AVR CURRENT'}]},
        {title: 'V Max DMD', values: [{title: 'V1 Max', field: 'V1_Max_DMD', excel: 'V1 Max DMD'}, {title: 'V2 Max', field: 'V2_Max_DMD',  excel: 'V2 Max DMD'}, {title: 'V3 Max', field: 'V3_Max_DMD', excel: 'V3 Max DMD'}]},
        {title: 'I Max DMD', values: [{title: 'I1 Max', field: 'I1_Max_DMD', excel: 'I1 Max DMD'}, {title: 'I2 Max', field: 'I2_Max_DMD', excel: 'I2 Max DMD'}, {title: 'I3 Max', field: 'I3_Max_DMD', excel: 'I3 Max DMD'}]}
      ]
    };
  },
  beforeMount() {
    const data = this.streamData[this.streamData.length - 1];
    if (data) {
      const parsed_data = this.getParsedData(data[1]);
      this.last_data = parsed_data;
      this.last_data_time = moment(data[0]).tz(this.projectTimezone).format('DD/MM/YYYY HH:mm');
      this.current_data = cloneDeep(this.last_data);
      this.current_data_time = this.last_data_time;
    }
  },
  computed: {
    projectTimezone(){
      return this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    },
    lang() {
      return this.$store.state.Global.lang;
    },
    absoluteStreamHistory() {
      return this.$store.state.ProjectStreams.absoluteStreamHistory;
    }
  },
  methods: {
    retrieveLatestData(){
      this.current_data = cloneDeep(this.last_data);
      this.current_data_time = this.last_data_time;
    },
    async updateCurrentData(custom_date, custom_time) {
      const [data, timestamp_string] = await this.getDataByAbsoluteTime(custom_date, custom_time);
      this.current_data = data;
      this.current_data_time = timestamp_string;
    },
    generateExcel(){
      const workbook = new Excel.Workbook();
      const columns = [
        {header: this.$t('Field'), key: 'field', width: 20},
        {header: this.$t('Value'), key: 'value', width: 15}
      ];
      const data = [];
      this.pm_fields.forEach((field_data) => {
        field_data.values.forEach((value) => {
          data.push({field: value.excel || value.title, value: this.current_data[value.field]});
        });
      });

      const sheet = workbook.addWorksheet(this.$t('Demands'));
      sheet.columns = columns;
      sheet.addRows(data);

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;'});
        saveAs(blob, `Cabinet ${this.cabinet_id} - Demands Data - ${Utils.getNow(this.projectTimezone)}.xlsx`);
      });
    }
  }
};
</script>

<style lang="scss">
.date-style {
  .v-text-field input {
    padding: 8px 0 1px !important;
  }

  .theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: #6a6a6a !important;
  }
}
</style>

<style scoped lang="scss">
.property-name {
  color: #6a6a6a;
}

.pick-date-style {
  .v-menu__content.theme--light.menuable__content__active {
    border-radius: 16px;
  }
}

.min-height-btn {
  .v-btn {
    height: 30px;
  }   
}
</style>