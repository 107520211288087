<template>
  <div v-if="loading" class="d-flex align-center justify-center" style="padding-top: 15px">
    <v-progress-circular indeterminate="indeterminate" color="primary" size="48"></v-progress-circular>
  </div>
  <v-layout v-else-if="noData && !loading" style="height: calc(100vh - 100px)">
    <v-layout column justify-center align-center fill-height>
      <h1>{{ $t('Device not found') }}...</h1>
      <v-btn color="#292F7D" class="mt-4 subheading" dark round @click="$router.push('/fullViewReport')">{{ $t('Back To Home Page') }}</v-btn>
    </v-layout>
  </v-layout>
  <v-layout v-else class="cabinet-details-screen pa-4" column>
    <v-layout row wrap align-center>
      <v-flex shrink>
        <v-icon medium @click="goBack" color="black">{{ lang === 'en' ? 'mdi-arrow-left' : 'mdi-arrow-right'}}</v-icon>
      </v-flex>
      <v-flex grow>
        <v-layout justify-center align-center>
          <span v-if="cabinetData.description" class="title font-weight-bold" :class="lang === 'en' ? 'ml-2' : 'mr-2'">{{ `${$t('Cabinet')} ${$t(cabinetData.id)} ${cabinetData.description}` }}</span>      
          <span v-else class="title font-weight-bold" :class="lang === 'en' ? 'ml-2' : 'mr-2'">{{ `${$t('Cabinet ID')}: ${$t(cabinetData.id)}` }}</span>      
          <span v-if="cabinetData.cabinet_type" class="property-name subheading font-weight-bold mx-3">{{ $t(cabinetData.cabinet_type) }}</span>          
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout justify-space-between row wrap align-center class="mt-3">
      <v-flex shrink d-flex xs12 style="overflow-x: auto">
        <v-card class="tabs-cabinet-details" style="background-color: white !important">
          <div :key="tab.value" v-for="tab in tabs" @click="setSelectedTab(tab.value)" :class="['tab-cabinet-details', selectedTab === tab.value ? 'selected-tab-cabinet-details' : '']">
            {{ $t(tab.title) }}
          </div>
        </v-card>
      </v-flex>
      <v-flex shrink d-flex xs12 style="overflow-x: auto">
        <v-layout class="mt-2" row wrap>
          <v-flex shrink>
            <v-layout row wrap align-center>
              <v-tooltip v-if="!cabinetData.cabinet_type" bottom style="z-index: 5">
                <v-layout row wrap slot="activator" align-center>
                  <v-img contain :src="getStreamStatus('sys___connected')" height="18" width="18"></v-img>
                  <span class="property-name subheading font-weight-medium mx-2">{{ device.sys___connected ? $t('Connected') : $t('Disconnected') }}</span>
                </v-layout>
                <span>{{ connection_state }}</span>
              </v-tooltip>
              <template v-if="services_config_status.display_alerts_service">
                <v-divider v-if="!cabinetData.cabinet_type" vertical :class="lang === 'en' ? 'mr-2' : 'ml-2'" style="border-width: 1px;"></v-divider>
                <v-tooltip bottom style="z-index: 5">
                  <v-layout row wrap slot="activator" align-center>
                    <v-img contain :src="getAlertServiceStatus" height="18" width="18"></v-img>
                    <span class="property-name subheading font-weight-medium mx-2">{{ $t('Alerts Service') }}</span>
                    <v-img v-if="services_config_status.partial_day" slot="activator" :class="lang === 'en' ? 'mr-2' : 'ml-2'" contain :src="require('@/assets/images/cabinet_details_icons/partial_day_icon.png')" height="20" width="20"></v-img>
                    <v-img v-else slot="activator" :class="lang === 'en' ? 'mr-2' : 'ml-2'" contain :src="require('@/assets/images/cabinet_details_icons/all_day_icon.png')" height="20" width="20"></v-img>
                  </v-layout>
                  <span v-if="!services_config_status.alert_is_on">
                    {{ $t('Alerts service is inactive')}}
                  </span>
                  <span v-else-if="services_config_status.partial_day">
                    {{ $t('Alerts active during the night') }} 
                    {{ lang === 'en' 
                        ? ` ${services_config_status.window.start} - ${services_config_status.window.end}`
                        : ` ${services_config_status.window.end} - ${services_config_status.window.start}` 
                    }}
                  </span>
                  <span v-else>
                    {{ $t('24/7 Alert Service') }}
                  </span>
                </v-tooltip>
              </template>
              <template v-if="!cabinetData.cabinet_type && services_config_status.display_cabinet_iq_service">
                <v-divider vertical :class="lang === 'en' ? 'mr-2' : 'ml-2'" style="border-width: 1px;"></v-divider>
                <v-img contain :src="getCabinetAlertServiceStatus" height="18" width="18"></v-img>
                <v-tooltip bottom style="z-index: 5">
                  <span
                    slot="activator" 
                    class="property-name subheading font-weight-medium"
                    :class="schedule_service.exist && schedule_service.active ? 'mx-2' : lang === 'en' ? 'ml-2' : 'mr-2'"
                  >
                    {{ $t('Cabinet IQ Alerts') }}
                  </span>
                  <span>{{ $t('Critical Alerts Service') }}</span>
                </v-tooltip>
              </template>
              <template v-if="schedule_service.exist && schedule_service.active">
                <v-divider vertical :class="lang === 'en' ? 'mr-2' : 'ml-2'" style="border-width: 1px;"></v-divider>
                <v-tooltip bottom style="z-index: 5">
                  <v-layout row wrap slot="activator" align-center>
                    <v-img contain :src="getCabinetScheduleStatus" height="18" width="18"></v-img>
                    <span class="property-name subheading font-weight-medium mx-2">{{ $t('On') }}:</span>
                    <span class="property-name subheading font-weight-bold">{{ schedule_service.start_time }}</span>
                    <span class="property-name subheading font-weight-medium mx-2">{{ $t('Off') }}:</span>
                    <span class="property-name subheading font-weight-bold">{{ schedule_service.end_time }}</span>
                  </v-layout>
                  <span v-if="schedule_service.is_astronomical">{{ `${$t('Devices astronomical updater')} ${schedule_service.updater}` }}</span>
                  <span v-else>{{ `${$t('Devices schedule updater')} ${schedule_service.updater}` }}</span>
                </v-tooltip>
              </template>
              <template v-if="cabinetData.installation_date !== 'none'">
                <v-divider vertical class="mx-2" style="border-width: 1px;"></v-divider>
                <v-tooltip bottom style="z-index: 5">
                  <span slot="activator" class="property-name subheading font-weight-bold">
                    {{ `${$t('Installation Date')} ${cabinetData.installation_date}` }}
                  </span>
                  <span>{{ cabinetData.installater === 'none' ? $t('No installer details') : `${$t('Installed by')} ${cabinetData.installater}` }}</span>
                </v-tooltip>
              </template>
              <template v-if="operation_state">
                <v-divider vertical class="mx-2" style="border-width: 1px;"></v-divider>
                <v-tooltip bottom style="z-index: 5">
                  <v-layout row wrap slot="activator" align-center>
                    <v-img :class="lang === 'en' ? 'mr-1' : 'ml-1'" contain :src="require('@/assets/images/cabinet_details_icons/operation_state.png')" height="20" width="20"></v-img>
                    <span class="property-name subheading font-weight-bold">{{ operation_state }}</span>
                  </v-layout>
                  <span>{{ $t('Operation State') }}</span>
                </v-tooltip>
              </template>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="blue-rounded-card pa-3 my-2">
      <v-flex xs12 xl5 class="mb-2">
        <v-layout row wrap>
          <img src="@/assets/images/cabinet_details_icons/cabinet.png" alt="" style="max-width: 20px" />
          <span :class="[lang === 'en' ? 'ml-1' : 'mr-1', 'subheading', 'font-weight-bold']">{{ device.name }}</span>
        </v-layout>
        <v-layout row wrap class="mt-3">
          <v-flex xs12 sm7>
            <v-layout>
              <v-flex xs4>
                <v-layout column>
                  <span class="property-name body-2">{{ $t('Address') }}</span>
                  <span class="property-name body-2">{{ $t('Managed Area') }}</span>            
                </v-layout>
              </v-flex>
              <v-flex xs8>
                <v-layout column>
                  <span class="body-2 font-weight-bold overflow-style"><span>{{ $t(cabinetData.address) }}</span></span>
                  <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.managed_area !== 'N/A' ? cabinetData.managed_area : $t(cabinetData.road) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm5>
            <v-layout>
              <v-layout column>
                <span class="property-name body-2">{{ $t('Incidents') }}</span>
                <span class="property-name body-2">{{ $t('Connected And Active') }}</span>
              </v-layout>
              <v-layout column>
                <v-layout row align-center>
                  <v-tooltip top style="z-index: 5">
                    <v-layout row wrap slot="activator" align-center>
                      <div class="red_alert square_alert"></div>
                      <span class="body-2 font-weight-bold overflow-style mx-1">{{ incidents_data.high }}</span>
                    </v-layout>
                    <span>{{ $t('High Priority') }}</span>
                  </v-tooltip>
                  <v-tooltip top style="z-index: 5">
                    <v-layout row wrap slot="activator" align-center>
                      <div class="yellow_alert square_alert" :class="lang === 'en' ? 'ml-1' : 'mr-1'"></div>
                      <span class="body-2 font-weight-bold overflow-style mx-1">{{ incidents_data.mid }}</span>
                    </v-layout>
                    <span>{{ $t('Medium Priority') }}</span>
                  </v-tooltip>
                  <v-tooltip top style="z-index: 5">
                    <v-layout row wrap slot="activator" align-center>
                      <div class="green_alert square_alert" :class="lang === 'en' ? 'ml-1' : 'mr-1'"></div>
                      <span class="body-2 font-weight-bold overflow-style mx-1">{{ incidents_data.low }}</span>
                    </v-layout>
                    <span>{{ $t('Low Priority') }}</span>
                  </v-tooltip>
                  <v-tooltip top style="z-index: 5">
                    <v-layout row wrap slot="activator" align-center>
                      <div class="info_alert square_alert" :class="lang === 'en' ? 'ml-1' : 'mr-1'"></div>
                      <span class="body-2 font-weight-bold overflow-style mx-1">{{ events_list.length }}</span>
                    </v-layout>
                    <span>{{ $t('Info') }}</span>
                  </v-tooltip>
                </v-layout>
                <div v-if="loading_project_devices">
                  <v-progress-circular indeterminate="indeterminate" color="primary"></v-progress-circular>
                </div>
                <span
                  v-else
                  class="body-2 font-weight-bold overflow-style"
                  :class="cabinetData.connected_fixtures_percentage < 60 ? 'red--text' : cabinetData.connected_fixtures_percentage > 89 ? 'green--text' : 'orange--text'"
                >
                  {{ `${cabinetData.connected_fixtures_percentage}%` }}
                </span>
              </v-layout>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 xl7>
        <v-layout column>
          <v-layout row wrap class="mt-2">
            <v-layout>
              <v-flex shrink><v-divider vertical light></v-divider></v-flex>
              <v-flex class="px-3">
                <span class="subheading font-weight-bold property-name">{{ $t('Fixtures') }}</span>
                <v-layout class="mt-2">
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.assigned_devices }}</span>
                    <span class="property-name body-2">{{ $t('Total') }}</span>
                  </v-layout>
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.controlled_devices }}</span>
                    <span class="property-name body-2">{{ $t('Controlled') }}</span>
                  </v-layout>
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.actual_controlled_devices }}</span>
                    <span class="property-name body-2">{{ $t('Actual controlled') }}</span>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex shrink><v-divider vertical light></v-divider></v-flex>
              <v-flex class="px-3">
                <span class="subheading font-weight-bold property-name">{{ $t('Poles') }}</span>
                <v-layout class="mt-2">
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.total_poles }}</span>
                    <span class="property-name body-2">{{ $t('Total') }}</span>
                  </v-layout>
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.controlled_poles }}</span>
                    <span class="property-name body-2">{{ $t('Controlled') }}</span>
                  </v-layout>
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.actual_controlled_poles }}</span>
                    <span class="property-name body-2">{{ $t('Actual controlled') }}</span>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex shrink><v-divider vertical light></v-divider></v-flex>
              <v-flex class="px-3">
                <span class="subheading font-weight-bold property-name">{{ $t('Communication') }}</span>
                <v-layout class="mt-2">
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.communicated_count }}</span>
                    <span class="property-name body-2">{{ $t('Communicated') }}</span>
                  </v-layout>
                  <v-layout column>
                    <span class="body-2 font-weight-bold overflow-style">{{ cabinetData.uncommunicated_count }}</span>
                    <span class="property-name body-2">{{ $t('Never') }}</span>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
    <HubTabs
      v-if="selectedTab"
      :selectedTab="selectedTab"
      :device="device"
      :cabinetDevices="cabinetDevices"
      :phasesLiveData="phasesLiveData"
      :cabinetData="cabinetData"
      :services_config="services_config"
      :services_config_status="services_config_status"
      :updateServiceStatus="updateServiceStatus"
      :updateMetaDeviceFields="updateMetaDeviceFields"
      :deviceStreams="cabinetStreams"
      :setDeviceStreams="setCabinetStreams"
      :sys_active_modified="sys___active_modified"
      :connection_state="connection_state"
      :operation_state_prop="operation_state_prop"
      :electricity_price_prop="electricity_price_prop"
      :incidents_data="incidents_data"
      :alerts_list="alerts_list"
      :events_list="events_list"
      @updateServicesConfig="updateServicesConfig"
      @phasesLiveDataChanged="setPhasesLiveData"
      @updateSchedule="updateScheduleData"
      @updateOperationState="updateOperationState"
      @updateTariffData="updateTariffData"
    />
    <PropEditor ref="propEditor" />
  </v-layout>
</template>

<script>
import HubTabs from '@/pages/mapView/components/MapTabs/devices/HubInfoTabs.vue';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import PropEditor from '@/components/PropEditor.vue';
import API, { Types } from '@/modules/API';

export default {
  components: {
    HubTabs,
    PropEditor
  },
  name: 'CabinetDetails',
  data() {
    return {
      noData: false,
      selectedTab: 'full-view',
      all_tabs: [
        // {title: 'Overview', value: 'overview', virtual: false, regular: true}, 
        {title: 'Full View', value: 'full-view', virtual: true, regular: true, readonly: true}, 
        {title: 'Energy', value: 'energy', virtual: false, regular: true, readonly: true},
        {title: 'Monitor', value: 'monitor', virtual: false, regular: true, readonly: true},
        {title: 'Cabinet Details', value: 'cabinet-details', virtual: true, regular: true, readonly: true},
        {title: 'Connections', value: 'connections', virtual: false, regular: true, readonly: true},
        {title: 'Control', value: 'control', virtual: false, regular: true, readonly: true},
        {title: 'Advanced', value: 'advanced', virtual: true, regular: true, readonly: true},
        {title: 'Billing', value: 'billing', virtual: false, regular: true, readonly: true},
        {title: 'Billing', value: 'virtual-billing', virtual: true, regular: false, readonly: true},
        {title: 'Attached Files', value: 'files', virtual: true, regular: true, readonly: false},
        {title: 'Incidents', value: 'incidents', virtual: true, regular: true, readonly: true}
      ],
      tabs: [],
      cabinetStreams: [],
      cabinetData: {
        id: '',
        installation_date: '',
        address: '',
        road: '',
        managed_area: '',
        cabinet_type: '',
        assigned_devices: '',
        controlled_devices: '',
        actual_controlled_devices: 0,
        total_poles: '',
        controlled_poles: '',
        actual_controlled_poles: 0,
        uncommunicated_count: 0,
        communicated_count: 0,
        description: ''
      },
      operation_state_prop: null,
      electricity_price_prop: null,
      sys___active_modified: null,
      sys___connected_modified: null,
      loading: true,
      device: null,
      phasesLiveData: [],
      services_config: {},
      services_config_status: {
        window: {start: '', end: ''},
        display_alerts_service: false,
        partial_day: false,
        enabled_turn_off: false,
        alert_is_on: false,
        display_schedule_service: false,
        schedule_is_on: false,
        display_cabinet_iq_service: false,
        cabinet_iq_is_on: false
      },
      schedule_service: {
        is_astronomical: false,
        updater: '',
        start_time: '',
        end_time: '',
        active: false,
        exist: false
      },
      alerts_list: [],
      events_list: [],
      incidents_data: { 
        high: 0,
        mid: 0,
        low: 0,
        cabinetNumber: '',
        supply: '',
        roadNumber: '',
        maxA: 60,
        devices: [] 
      },
      loading_project_devices: true
    };
  },
  async mounted() {
    this.loading = true;
    this.$store.commit('Global/setPageTitle', 'Cabinet Details');

    if (this.cabinets_list.length === this.cabinets_count) {
      await this.fetchCabinetData();
      this.setIncidentsData();
      this.loading = false;
    }
  
    if (this.$route.params.pid) {
      this.setSelectedTab('incidents');
    }

    if (this.projectDevices.length === this.devices_count) {
      this.loading_project_devices = false;
    }
  },
  beforeDestroy(){
    if (!this.$route.path.includes('cabinet-management')) {
      this.$store.commit('Global/setSelectedManagedArea', '');
    }
  },
  methods: {
    setIncidentsData(){
      this.setCabinetDataForIncidents();
      this.setCabinetAlertsList();
      this.setCabinetEventsList();
    },
    setCabinetDataForIncidents(){
      const current_cabinet = {...cloneDeep(this.device)};
      current_cabinet['services.config'] = PropUtils.parseProperty('services.config', current_cabinet);
      if (current_cabinet['services.config'].hasOwnProperty('alerts')) {
        current_cabinet['meta.alert_config'] = current_cabinet['services.config'].alerts;
      }else current_cabinet['meta.alert_config'] = {};

      this.incidents_data = {...current_cabinet};
      this.incidents_data.maxA = (+current_cabinet['meta.alert_config']['max_a_phase_total'] || 60);
      this.incidents_data.cabinetNumber = this.cabinetData.id;
      this.incidents_data.supply = this.device['meta.device'][`iec's_supply_pole_id`] || 'N/A';
      this.incidents_data.roadNumber = this.device['meta.device'].managed_area || this.device['meta.device'].road || '';
      this.incidents_data.devices = this.$store.getters['Reports/cabinets_fixtures'].get(this.device.id);
    },
    async fetchCabinetData(){
      const device_id = this.$route.params.id || this.$route.params.cid || '';
      if (device_id) {
        this.device = this.$store.getters['Reports/commissionedCabinets_map'].get(device_id);
      }
      if (this.device) {
        const response = await API.get(Types.DEVICES, device_id + '/streams/', {});
        if (response.results) {
          this.setCabinetStreams(response.results);
          if (Utils.hasCabinetClass(this.device.class_name)) {
            this.setStreamChangeTime(this.device.id, 'sys___active');
            this.setStreamChangeTime(this.device.id, 'sys___connected');
          }
        }
        await this.fetchOperationState();
        this.setCabinetData();
        await this.setCurrentCabinetSchedule();
        this.fetchElectricityPrice();
      }else {
        this.noData = true;
      }
    },
    async setCurrentCabinetSchedule(){
      let cabinet_schedule;
      try {
        const response = await API.get(Types.DEVICES, `${this.device.id}/properties`, { name: 'meta.devices_schedule' });
        cabinet_schedule = (response && response.results && response.results[0] && response.results[0].value) || null;
      } catch (e) {
        cabinet_schedule = null;
      }
      
      if (cabinet_schedule && typeof cabinet_schedule === 'object' && Object.entries(cabinet_schedule).length){
        this.schedule_service.exist = true;
        this.schedule_service.active = cabinet_schedule.active;
        this.schedule_service.is_astronomical = cabinet_schedule.is_astronomical;
        this.schedule_service.updater = cabinet_schedule.updater;

        if (cabinet_schedule.is_astronomical){
          this.schedule_service.start_time = Utils.getAstronomicalTime(cabinet_schedule.dusk, this.dusk_dawn_data.dusk_timestamp, this.dusk_dawn_data.dusk, this.projectTimezone);
          this.schedule_service.end_time = Utils.getAstronomicalTime(cabinet_schedule.dawn, this.dusk_dawn_data.dawn_timestamp, this.dusk_dawn_data.dawn, this.projectTimezone);
        }else {
          this.schedule_service.start_time = Utils.convertUtcTimeToTimezone(cabinet_schedule.on, this.projectTimezone, 'HH:mm');
          this.schedule_service.end_time = Utils.convertUtcTimeToTimezone(cabinet_schedule.off, this.projectTimezone, 'HH:mm');
        }
      }
    },
    async fetchOperationState(){
      try {
        const response = await API.get(Types.DEVICES, `${this.device.id}/properties`, { name: 'cabinet.operation_state' });
        this.operation_state_prop = (response && response.results && response.results[0] && response.results[0].value) || null;
      } catch (e) {
        this.operation_state_prop = null;
      }
    },
    async fetchElectricityPrice(){
      try {
        const response = await API.get(Types.DEVICES, `${this.device.id}/properties`, { name: 'electricity.price' });
        this.electricity_price_prop = (response && response.results && response.results[0] && response.results[0].value) || null;
      } catch (e) {
        this.electricity_price_prop = null;
      }
    },
    updateScheduleData(){
      setTimeout(() => {
        this.setCurrentCabinetSchedule();
      }, 2000);
    },
    updateOperationState(new_operation_state){
      this.operation_state_prop = cloneDeep(new_operation_state);
    },
    updateTariffData(new_tariff_data){
      this.electricity_price_prop = cloneDeep(new_tariff_data);
    },
    async setStreamChangeTime(deviceId, streamName) {
      const response = await API.get(Types.DEVICES, `${deviceId}/streams/${deviceId}.${streamName}/latest/`);
      const timezone = this.$store.state.User.project.id === 'allProjects'
        ? this.$store.state.Global.allProjectsTimezone
        : this.$store.state.Global.timezone;

      this[`${streamName}_modified`] = response.timestamp 
        ? Utils.convertTimestamp(response.timestamp, 'DD.MM.YYYY HH:mm', timezone)
        : 'N/A';
    },
    setCabinetStreams(value){
      this.cabinetStreams = value;
    },
    setCabinetData() {
      const metaDevice = cloneDeep(this.device['meta.device']);
      this.cabinetData.id = metaDevice.cabinet_id || 'N/A';
      this.cabinetData.installation_date = this.device['meta.commission'].date;
      this.cabinetData.installater = this.device['meta.commission'].commissioner;
      this.cabinetData.address = metaDevice.cabinet_address || (metaDevice.estimated_address && `${metaDevice.estimated_address} (${this.$t('estimated')})`) || 'N/A';
      this.cabinetData.road = metaDevice.road || 'N/A';
      this.cabinetData.managed_area = metaDevice.managed_area || 'N/A';
      this.cabinetData.cabinet_type = Utils.hasVirtualCabinetClass(this.device.class_name, this.device['meta.device']) ? 'Virtual' : '';
      this.cabinetData.assigned_devices = metaDevice.hasOwnProperty('assigned_devices') && metaDevice.assigned_devices ? metaDevice.assigned_devices : 0;
      this.cabinetData.controlled_devices = metaDevice.hasOwnProperty('controlled_devices') && metaDevice.controlled_devices ? metaDevice.controlled_devices : 0;
      this.cabinetData.actual_controlled_devices = this.cabinetDevices.length;
      this.cabinetData.total_poles = metaDevice.hasOwnProperty('total_poles') && metaDevice.total_poles ? metaDevice.total_poles : 0;
      this.cabinetData.controlled_poles = metaDevice.hasOwnProperty('controlled_poles') && metaDevice.controlled_poles ? metaDevice.controlled_poles : 0;
      this.cabinetData.actual_controlled_poles = this.getPolesCount();
      this.cabinetData.uncommunicated_count = this.cabinetDevices.filter((device) => device.sys___active === -3).length;
      this.cabinetData.communicated_count = this.cabinetData.actual_controlled_devices - this.cabinetData.uncommunicated_count;
      this.cabinetData.description = metaDevice.hasOwnProperty('description') && metaDevice.description || '';
      this.setCabinetServiceIsOn();
      this.setCabinetTabs();
      this.setConnectedAndActive();
    },
    setConnectedAndActive(){
      const controlled_fixtures = this.$store.getters['Reports/cabinets_fixtures'].get(this.device.id) || [];
      this.cabinetData.controlled_fixtures_count = controlled_fixtures.length;
      this.cabinetData.connected_fixtures_percentage = this.cabinetData.controlled_fixtures_count 
        ? Math.ceil((this.getConnectedPercentage(controlled_fixtures) / this.cabinetData.controlled_fixtures_count) * 100)
        : 0;
    },
    getConnectedPercentage(controlled_devices){
      return controlled_devices.filter((device) => device.sys___active === 1 && device.sys___connected === 1).length;
    },
    setCabinetTabs(){
      let final_tabs = this.cabinetData.cabinet_type 
        ? this.all_tabs.filter((tab) => tab.virtual)
        : this.all_tabs.filter((tab) => tab.regular);
      if (this.readonly_user) {
        final_tabs = final_tabs.filter((tab) => tab.readonly);
      }

      this.tabs = final_tabs;
    },
    setCabinetServiceIsOn(){
      this.services_config = PropUtils.parseProperty('services.config', this.device);
      const alert_config = this.services_config.hasOwnProperty('alerts') ? this.services_config['alerts'] : {};
      const schedule_config = this.services_config.hasOwnProperty('schedule_validation') ? this.services_config['schedule_validation'] : {};
      let alert_is_on = true, schedule_is_on = false;

      if (schedule_config.hasOwnProperty('kill_switch')) {
        this.services_config_status.display_schedule_service = true;
        if (schedule_config.kill_switch === false) schedule_is_on = true;
      }

      if (Object.keys(alert_config).length){
        this.services_config_status.display_alerts_service = true;
        this.services_config_status.display_cabinet_iq_service = true && !this.cabinetData.cabinet_type;

        if (!alert_config.hasOwnProperty('kill_switch_analytics_alerts')) {
          alert_config.kill_switch_analytics_alerts = false;
        }
        if (!alert_config.hasOwnProperty('kill_switch_cabinet_alerts')) {
          alert_config.kill_switch_cabinet_alerts = false;
        }

        const window = this.getAlertsWindowByTimestamp(alert_config.window);
        if (window){
          // alert_on_window = +localStorage.getItem('now') > window.end && +localStorage.getItem('now') < window.start ? false : true;
          this.setAlertsWindowByTimezone(window);
          this.setAlertsTimeRange(alert_config.window);
        }
        if (alert_config.kill_switch_analytics_alerts === true) {
          alert_is_on = false;
        }
      }

      this.services_config_status.alert_is_on = alert_is_on;
      this.services_config_status.schedule_is_on = schedule_is_on;
      this.services_config_status.enabled_turn_off = this.isManager || this.userRole === 'CA' || this.userRole === 'CM';
      this.services_config_status.cabinet_iq_is_on = !alert_config.kill_switch_cabinet_alerts;
    },
    getAlertsWindowByTimestamp(window){
      if (window && Object.keys(window).length && window.start && window.end) {
        const alert_window = {...window};
        alert_window.start = Utils.getDateWithSpecificTime(window.start, +localStorage.getItem('now'));
        alert_window.end = Utils.getDateWithSpecificTime(window.end, +localStorage.getItem('now'));
        return alert_window;
      }
      return null;
    },
    setAlertsWindowByTimezone(window){
      const start_by_timezone = Utils.convertTimestamp(window.start, 'HH:mm', this.projectTimezone);
      const end_by_timezone = Utils.convertTimestamp(window.end, 'HH:mm', this.projectTimezone);
      this.services_config_status.window = {start: start_by_timezone, end: end_by_timezone};
    },
    setAlertsTimeRange(window){
      const [start_hours, start_minutes] = window.start.split(':');
      const [end_hours, end_minutes] = window.end.split(':');
      const start_timestamp = moment().utcOffset(0).set({hour: +start_hours, minute: +start_minutes}).valueOf();
      const end_yesterdat_timestamp = moment().utcOffset(0).subtract(1, 'days').set({hour: +end_hours, minute: +end_minutes}).valueOf();
      const end_timestamp = moment().utcOffset(0).set({hour: +end_hours, minute: +end_minutes}).valueOf();
      
      const difference1 = Math.abs(start_timestamp - end_yesterdat_timestamp);
      const difference2 = Math.abs(start_timestamp - end_timestamp);
      if (difference1 > 40 * 60000 && difference2 > 40 * 60000){ // convert 40 minutes to milliseconds
        this.services_config_status.partial_day = true;
      }else {
        this.services_config_status.partial_day = false;
      }
    },
    getPolesCount() {
      const poles_and_circuits = this.cabinetDevices.filter((device) => {
        const meta_device = this.$store.getters['Reports/commissioned_map'].get(device.id)['meta.device'];
        return meta_device.pole_number && meta_device.circuit_number;
      });
      const poles_by_circuit = poles_and_circuits.reduce((acc, curr) => {
        const circuit_number = this.$store.getters['Reports/commissioned_map'].get(curr.id)['meta.device'].circuit_number.toString();
        const pole_number = this.$store.getters['Reports/commissioned_map'].get(curr.id)['meta.device'].pole_number.toString();
        acc[circuit_number] 
          ? acc[circuit_number].add(pole_number) 
          : acc[circuit_number] = new Set([pole_number]);
        return acc;
      }, {});
      const poles = new Set();
      this.cabinetDevices.filter((device) => !poles_and_circuits.includes(device)).forEach((device) => {
        const pole_number = this.$store.getters['Reports/commissioned_map'].get(device.id)['meta.device'].pole_number;
        if (pole_number) poles.add(pole_number);
      });
      const count_by_circuit = Object.values(poles_by_circuit).reduce((acc, curr) => acc + curr['size'], 0);
      return poles.size + Number(count_by_circuit);
    },
    goBack(){
      this.project.company === 'allProjects'
        ? this.$router.push(`/${this.$route.params.location}/alerts/${this.$route.params.pid}`)
        : this.$router.go(-1);
    },
    setSelectedTab(tabName) {
      this.selectedTab = tabName;
    },
    getStreamStatus(stream) {
      return this.device && this.device[stream]
        ? require('@/assets/images/cluster_icons/green-square-rounded-24.png')
        : require('@/assets/images/cluster_icons/red-square-rounded-24.png');
    },
    getLastActivity(sys_active_modified) {
      const sys_active_modified_utc = moment.utc(sys_active_modified * 1000).format();
      return moment.tz(sys_active_modified_utc, this.$store.state.Global.timezone).format('DD.MM.YYYY HH:mm');
    },
    setPhasesLiveData(live_data) {
      this.phasesLiveData = cloneDeep(live_data);
    },
    updateMetaDeviceFields(meta_device){
      this.device['meta.device'] = {...meta_device};
      this.setCabinetData();
    },
    updateServiceStatus(service_type, services_status){
      this.services_config_status = {...services_status};
      const services_property = {
          name: 'services.config',
          objectID: this.device.id,
          objectType: Types.DEVICES
      };

      if (service_type === 'alerts'){
        this.services_config.alerts.kill_switch_analytics_alerts = !this.services_config_status.alert_is_on;
      }else if (service_type === 'schedules'){
        this.services_config.schedule_validation.kill_switch = !this.services_config_status.schedule_is_on;
        if (this.services_config.schedule_validation.kill_switch) {
          let last_change = moment().utcOffset(0).valueOf(); // milliseconds
          last_change = Math.floor(last_change / 1000); // seconds
          this.services_config.schedule_validation.last_change = last_change;
        }
      }else if (service_type === 'cabinet_iq'){
        this.services_config.alerts.kill_switch_cabinet_alerts = !this.services_config_status.cabinet_iq_is_on;
      }
      
      this.$refs.propEditor.save(
          this.services_config,
          services_property,
          () => this.device['services.config'] = JSON.stringify(this.services_config),
          `Service Status has been successfully updated`
      );
    },
    setCabinetAlertsList(){
      this.alerts_list = [];
      this.incidents_data.low = 0;
      this.incidents_data.mid = 0;
      this.incidents_data.high = 0;

      const project_id = this.$route.params.pid || this.project.id;
      const project_alerts = this.alerts[project_id];
      if (!project_alerts || !project_alerts.cabinets) return;

      const cabinet_id = this.device['meta.device'].cabinet_id;
      if (!cabinet_id || !project_alerts.cabinets[cabinet_id]) {
        return;
      }

      const cabinet_alerts = project_alerts.cabinets[cabinet_id].list;
      if (!cabinet_alerts.length) return;

      const high_priority = cabinet_alerts.filter((alert) => alert.priority_id === 3).sort((a, b) => a.time - b.time);
      const mid_priority = cabinet_alerts.filter((alert) => alert.priority_id === 2).sort((a, b) => a.time - b.time);
      const low_priority = cabinet_alerts.filter((alert) => alert.priority_id === 1).sort((a, b) => a.time - b.time);
      this.alerts_list = cloneDeep([...high_priority, ...mid_priority, ...low_priority]);
      this.incidents_data.high = high_priority.length;
      this.incidents_data.mid = mid_priority.length;
      this.incidents_data.low = low_priority.length;
    },
    updateServicesConfig(new_config){
      this.services_config = cloneDeep(new_config);
    },
    setCabinetEventsList(){
      this.events_list = [];
      const project_id = this.project.id === 'allProjects' && this.device.project_id || this.project.id;
      const project_events = this.events[project_id];
      if (!project_events || !project_events.cabinets) return;

      const cabinet_id = this.device['meta.device'].cabinet_id;
      if (!cabinet_id || !project_events.cabinets[cabinet_id]) {
        return;
      }
      const cabinet_events = project_events.cabinets[cabinet_id].list;
      if (!cabinet_events.length) return;
      this.events_list = cloneDeep(cabinet_events);
    }
  },
  computed: {
    connection_state(){
      const modified_text = this.sys___connected_modified ? `${this.$t('since')} ${this.sys___connected_modified}` : '';
      return this.sys___connected_modified !== undefined && this.sys___connected_modified !== null
        ? this.device.sys___connected
            ? `${this.$t('Connected')} ${modified_text}`
            : `${this.$t('Disconnected')} ${modified_text}`
        : this.$t('N/A');
    },
    operation_state(){
      const date = this.operation_state_prop
        ? this.operation_state_prop.cabinet_opstate === 'installing' || !this.operation_state_prop.date
          ? null
          : Utils.convertTimestamp(this.operation_state_prop.date * 1000, 'DD/MM/YYYY HH:mm', this.projectTimezone)
          : null;

      if (!this.operation_state_prop) return '';
      
      return this.operation_state_prop.cabinet_opstate === 'installing'
        ? this.$t('Installing')
        : this.operation_state_prop.cabinet_opstate === 'delivered' && date
          ? `${this.$t('Operational since')} ${date}`
          : this.operation_state_prop.cabinet_opstate === 'delivered' && !date
            ? this.$t('Operational')
            : this.operation_state_prop.cabinet_opstate === 'retrofit' && date
              ? `${this.$t('Retrofit since')} ${date}`
              : this.operation_state_prop.cabinet_opstate === 'retrofit' && !date
                ? this.$t('Retrofit')
                : '';
    },
    readonly_user(){
      return this.$store.state.Global.readonly_user;
    },
    lang(){
      return this.$store.state.Global.lang;
    },
    getAlertServiceStatus() {
      return this.services_config_status.alert_is_on
        ? require('@/assets/images/cluster_icons/green-square-rounded-24.png')
        : require('@/assets/images/cluster_icons/grey-square-rounded-24.png');
    },
    getCabinetAlertServiceStatus() {
      return this.services_config_status.cabinet_iq_is_on
        ? require('@/assets/images/cluster_icons/green-square-rounded-24.png')
        : require('@/assets/images/cluster_icons/grey-square-rounded-24.png');
    },
    getCabinetScheduleStatus() {
      return this.schedule_service.active
        ? require('@/assets/images/cluster_icons/green-square-rounded-24.png')
        : require('@/assets/images/cluster_icons/grey-square-rounded-24.png');
    },
    getGeoLocation() {
      return Utils.deviceLocation(this.device);
    },
    projectDevices() {
      return this.$store.state.Reports.reportsList;
    },
    project() {
      return this.$store.state.User.project;
    },
    projectProperties() {
      return this.$store.state.Props.list;
    },
    projectTimezone() {
      return this.$store.state.User.project.id === 'allProjects' && this.$store.state.Global.allProjectsTimezone || this.$store.state.Global.timezone;
    },
    isManager(){
      return this.$store.state.User.isManager;
    },
    userRole(){
      return this.$store.state.User.role;
    },
    // devicesOn(){
    //   return this.lang === 'en' 
    //     ? `${this.cabinetDevices.filter((device) => device.power > 0).length} / ${this.cabinetDevices.length}`
    //     : `${this.cabinetDevices.length} / ${this.cabinetDevices.filter((device) => device.power > 0).length}`;
    // },
    cabinetDevices() {
      return [...this.$store.getters['Reports/commissionedFixtures_map'].values()]
        .filter((device) => device['meta.device'].cabinet_id && this.cabinetData.id === device['meta.device'].cabinet_id);
    },
    alerts(){
      return this.$store.state.Projects.alerts;
    },
    events(){
      return this.$store.state.Projects.events;
    },
    cabinets_count(){
      return this.$store.state.Reports.cabinetsCount;
    },
    cabinets_list(){
      return this.$store.getters['Reports/commissionedCabinets'];
    },
    devices_count(){
      return this.$store.state.Reports.devicesCount;
    },
    dusk_dawn_data() {
      return this.$store.state.Global.dusk_dawn_data;
    }
  },
  watch: {
    async cabinets_count(){
      await this.fetchCabinetData();
      this.setIncidentsData();
      this.loading = false;
    },
    projectDevices(){
      if (this.devices_count === this.projectDevices.length) {
        this.loading_project_devices = false;
        this.setConnectedAndActive();
        this.incidents_data.devices = this.$store.getters['Reports/cabinets_fixtures'].get(this.device.id);
      }
    },
    alerts(){
      this.setCabinetAlertsList();
    },
    events(){
      this.setCabinetEventsList();
    }
  }
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #292f7d;
  border-radius: 5px;
}

.title {
  font-family: 'Open Sans', 'Arial', monospace !important;
}

.section-title {
  color: #0b1146;
}

.cabinet-details-screen {
  background-color: #f2f4f8;
}

.tabs-cabinet-details {
  z-index: 1;
  border-radius: 12px;
  border: 1px solid #332a8f !important;
}

.tab-cabinet-details {
  padding: 6px 8px 6px 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  height: 100%;
  justify-content: center;
  align-content: center;
  color: #332a8f;
}

.selected-tab-cabinet-details {
  border-radius: 12px;
  background-color: #332a8f;
  color: #fff !important;
}

.overflow-style {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-style:hover {
  overflow: visible;
}

.overflow-style:hover span {
  position: relative;
  background-color: white;
  box-shadow: 0 0 4px 0 black;
  border-radius: 5px;
}

.property-name {
  color: #6a6a6a;
}

.status-div-square {
  width: 20px;
  height: 20px;
}

.green-background {
  background-color: #91e15d;
}

.grey-background {
  background-color: #6598ae;
}
</style>
