












































































































































































































































































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Watch, Prop } from 'vue-property-decorator';
import { Global, User } from '@/store';
import Utils from '@/modules/Utils';
import cloneDeep from 'lodash/cloneDeep';
import PropUtils from '@/modules/PropUtils';
// @ts-ignore
import VueQRCodeComponent from 'vue-qrcode-component';
import PropEditor from '@/components/PropEditor.vue';
import { Types } from '@/modules/API';
import moment from 'moment';
import OperationHistoryDialog from './components/OperationHistoryDialog.vue';

@Component({ 
    components: {
        VueQRCodeComponent,
        PropEditor,
        OperationHistoryDialog
    }
})

export default class AdvancedTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @User.State('role') role;
    @User.State('isManager') isManager;
    @User.State('username') username;

    // @ts-ignore
    @Prop() device;
    @Prop() mqtt;
    @Prop() deviceStreams;
    @Prop() streamActiveObject;
    @Prop() propsList;
    @Prop() outputs;
    @Prop() isCompulab;
    @Prop() liveReadingsAdvanced;
    @Prop() currentTime;
    @Prop() services_config;
    @Prop() services_config_status;
    @Prop() updateServiceStatus;
    @Prop() sys_active_modified;
    @Prop() connection_state;
    @Prop() mqtt_version;
    @Prop() operation_state_prop;
    @Prop() electricity_price_prop;

    streamConnectedObject = null;
    sysConnectedChangeTime = null;
    sysActiveChangeTime = null;
    streamRssi = null;
    deviceVersion = null;
    services_status = {
        window: {start: '', end: ''},
        display_alerts_service: false,
        enabled_turn_off: false,
        alert_is_on: false,
        display_schedule_service: false,
        schedule_is_on: false,
        display_cabinet_iq_service: false,
        cabinet_iq_is_on: false
    };
    loading = true;
    qrcode = '';
    size = 130;
    operation_state = '';
    tariff_type = '';
    tariff_tou = {
        tariff_1: 0,
        tariff_2: 0,
        tariff_3: 0
    };
    tariff_fixed = {
        tariff_1: 0,
        tariff_2: 0,
        tariff_3: 0
    };
    max_daily_consumption = null;
    utils_module = Utils;
    operation_history_dialog = false;

    openIcon = require('@/assets/images/cabinet_details_icons/open_switch.jpeg');
    closeIcon = require('@/assets/images/cabinet_details_icons/closed_switch.jpeg');

    mounted() {
        this.loading = true;
        this.setProperties();
        this.services_status = cloneDeep(this.services_config_status);
        if (this.services_config && this.services_config.alerts && this.services_config.alerts.cabinet_daily_max_current_consumption_ampere) {
            this.max_daily_consumption = +this.services_config.alerts.cabinet_daily_max_current_consumption_ampere;
        }
        this.generateQRcode();
        if (this.operation_state_prop) {
            this.operation_state = this.operation_state_prop.cabinet_opstate;
        }
        if (this.electricity_price_prop) {
            this.tariff_type = this.electricity_price_prop.type;
            if (this.tariff_type === 'fixed') {
                this.tariff_fixed.tariff_1 = this.electricity_price_prop.tariff_1;
            }else {
                this.tariff_tou.tariff_1 = this.electricity_price_prop.tariff_1;
                this.tariff_tou.tariff_2 = this.electricity_price_prop.tariff_2;
                this.tariff_tou.tariff_3 = this.electricity_price_prop.tariff_3;
            }
        }
        this.loading = false;
    }

    get is_manager(){
        return this.role === 'CA' || this.isManager;
    }

    get show_operation_history() {
        return this.role === 'CA' || this.role === 'CM';
    }

    get operation_states(){
        return [
            {
                text: this.$t('Installing'),
                value: 'installing'
            },
            {
                text: this.$t('Operational'),
                value: 'delivered'
            },
            {
                text: this.$t('Retrofit'),
                value: 'retrofit'
            }
        ];
    }

    get tariff_options(){
        return [
            {
                text: this.$t('Fixed'),
                value: 'fixed'
            },
            {
                text: this.$t('TOU'),
                value: 'tou'
            }
        ];
    }

    get disabled_update_tariff (){
        return this.tariff_type === 'fixed' 
            ? this.tariff_fixed.tariff_1 !== 0 && !Number(this.tariff_fixed.tariff_1)
            : (this.tariff_tou.tariff_1 !== 0 && !Number(this.tariff_tou.tariff_1) ||
                this.tariff_tou.tariff_2 !== 0 && !Number(this.tariff_tou.tariff_2) || 
                this.tariff_tou.tariff_3 !== 0 && !Number(this.tariff_tou.tariff_3));

    }

    updateTariffData(){
        const prop = {
          name: 'electricity.price',
          objectID: this.device.id,
          objectType: Types.DEVICES
        };
        
        const electricity_tariff_to_server = this.tariff_type === 'fixed'
            ? { type: 'fixed', ...this.tariff_fixed }
            : { type: 'tou', ...this.tariff_tou };

        (this.$refs.propEditor as PropEditor).save(
          electricity_tariff_to_server,
          prop,
          () => {
            this.$emit('updateTariffData', electricity_tariff_to_server);
          },
          'Cabinet tariff updated successfully'
        );
    }

    updateMaxDailyConsumption() {
        const prop = {
          name: 'services.config',
          objectID: this.device.id,
          objectType: Types.DEVICES
        };
        
        const alerts_config = this.services_config.alerts || {};
        const services_config_to_server = {
            ...this.services_config,
            alerts: {
                ...alerts_config,
                cabinet_daily_max_current_consumption_ampere: +this.max_daily_consumption
            }
        };

        (this.$refs.propEditor as PropEditor).save(
          services_config_to_server,
          prop,
          () => {
            this.$emit('updateServicesConfig', services_config_to_server);
          },
          'Max daily consumption updated successfully'
        );
    }

    updateOperationState(){
        const prop = {
          name: 'cabinet.operation_state',
          objectID: this.device.id,
          objectType: Types.DEVICES
        };

        let last_change = moment().utcOffset(0).valueOf(); // milliseconds
        last_change = Math.floor(last_change / 1000); // seconds
        
        const operation_state_to_server = {
            cabinet_opstate: this.operation_state,
            updater: this.username,
            date: last_change
        };

        (this.$refs.propEditor as PropEditor).save(
          operation_state_to_server,
          prop,
          () => {
            this.$emit('updateOperationState', operation_state_to_server);
          },
          'Operation state updated successfully'
        );
    }

    validateMinValue(prop_name, inner_field){
        if (inner_field) {
            if (+this[prop_name][inner_field] < 0) {
                this[prop_name][inner_field] = Math.abs(+this[prop_name][inner_field]);
            }
        }else if (+this[prop_name] < 0) {
            this[prop_name] = Math.abs(+this[prop_name]);
        }
    }

    generateQRcode(){
        if (this.device.class_name.includes('sbc_cabinet')){
            this.qrcode = PropUtils.getTeltonicaHardwareid(this.device);
        }else {
            const hardwareidProp = PropUtils.parseProperty('device.hardwareid', this.device);
            this.qrcode = hardwareidProp && hardwareidProp.id ? hardwareidProp.id : '';
        }
    }


    setProperties() {
        const deviceVersion = this.propsList.results.find((el) => el.name === 'device.version');
        this.deviceVersion = deviceVersion ? deviceVersion.value : null;
    }

    getLastRssi(){
        return this.device.rssi !== null && this.device.rssi !== undefined ? `${this.device.rssi} [dBm]` : this.$t('N/A');
    }

    getLocation(){
        const coordinates = this.device['meta.location'];
        return this.device.has_valid_location
            ? `${coordinates.lat.toFixed(3)}, ${coordinates.lng.toFixed(3)}`
            : this.$t('N/A');
    }

    getActiveStatus(){
        const modified_text = this.sys_active_modified ? `${this.$t('since')} ${this.sys_active_modified}` : '';
        return this.sys_active_modified !== undefined && this.sys_active_modified !== null
                ? this.device.sys___active
                    ? `${this.$t('Active')} ${modified_text}`
                    : `${this.$t('Inactive')} ${modified_text}`
                : this.$t('N/A');
    }

    getCreationDate() {
        return this.device.created_ts 
            ? this.getTimezoneDate(this.device.created_ts)
            : 'N/A';
    }

    getTimezoneDate(timestamp){
        return Utils.convertTimestamp(timestamp * 1000, 'DD/MM/YYYY HH:ss', this.projectTimezone);
    }

    getLiveData() {
        this.publishMqttMessage('-1', 'control');
        this.publishMqttMessage('-1', 'digital_input');
        this.publishMqttMessage('-1', 'live_data');
    }

    publishMqttMessage(message, streamName) {
        const mqtt_publish_data = {
            version_num: this.mqtt_version,
            device_id: this.device.id,
            stream_id: this.mqtt_version === 3 ? `${this.device.id}.${streamName}` : streamName,
            message_type: 'cmd',
            format: 'minimal',
            stream_value: message,
            options: {
                retain: false,
                qos: 1
            }
        };

        this.mqtt.publishToSingleDevice(mqtt_publish_data);
    }

}
